import React, { useEffect, useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const formQuestion = {
  questionTitle: '',
  questionIndex: 1,
  subTitle: '',
  questionSlug: '',
  type: '',
  choices: '',
  questionImg: '',
  questionSubtitle: '',
  choice1: '',
  choice2: '',
  choice3: '',
};

const InputPollQuestion = () => {
  // state
  const [formData, setFormData] = useState(formQuestion);
  const [imgArray, setImgArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const [endpoint] = useState(process.env.REACT_APP_QORE_ENDPOINT);
  const [orgId] = useState(process.env.REACT_APP_QORE_ORG_ID);
  const [projectId] = useState(process.env.REACT_APP_QORE_PROJECT_ID);

  // qore
  const client = qoreContext.useClient();
  const { insertRow } = qoreContext.view('allSkincarePoll').useInsertRow();
  const { data: existingData, status } = qoreContext
    .view('allSkincarePoll')
    .useListRow({});

  // history
  const history = useHistory();

  const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  };

  const handleChange = (e) => {
    if (e.target.name === 'questionTitle') {
      const slug = camelize(e.target.value);
      setFormData({
        ...formData,
        questionTitle: e.target.value,
        questionSlug: slug,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDeleteImage = (deletedName) => {
    let newImages = [...imgArray];
    newImages = newImages.filter((item) => item.name !== deletedName);
    setImgArray(newImages);
  };

  const createNewColumn = async (newQuestion) => {
    let isSuccess = false;
    let fieldId = '';

    try {
      const payload = {
        name: newQuestion.questionSlug.replace(/[^\w\s]/gi, ''),
        type: 'text',
        defaultValue: '',
      };

      const {
        data: { id },
      } = await qoreContext.client.project.axios.post(
        `${endpoint}/orgs/${orgId}/projects/${projectId}/tables/skincarePollResponses/fields`,
        payload
      );

      if (!id) {
        const err = { msg: 'create new column on tables failed' };
        throw err;
      }

      const {
        data: { ok: isFieldInserted },
      } = await qoreContext.client.project.axios.post(
        `${endpoint}/orgs/${orgId}/projects/${projectId}/views/allSkincarePollResponses/fields/${id}`
      );

      if (!isFieldInserted) {
        const err = { msg: 'create new column on views failed' };
        throw err;
      }

      isSuccess = true;
      fieldId = id;
    } catch (err) {
      console.error(err);
    } finally {
      return {
        isSuccess,
        fieldId,
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      var choiceArray = {};
      let questionsData = [];
      let questionImg = null;

      // bikin promises, upload gambar
      if (formData.questionType === 'multipleChoice') {
        choiceArray = {
          choice: [
            { title: formData.choice1 },
            { title: formData.choice2 },
            { title: formData.choice3 },
          ],
        };

        questionsData.push({
          questionTitle: formData.questionTitle,
          questionIndex: formData.questionIndex,
          subTitle: formData.questionSubtitle,
          questionSlug: formData.questionSlug,
          type: formData.questionType,
          choices: choiceArray,
          questionImg: null,
          fieldId: null,
        });
      } else if (formData.questionType === 'shortAnswer') {
        const promises = imgArray.map((img) =>
          client
            .view('allSkincarePoll')
            .upload(img.file)
            .then((url) => {
              return {
                name: img.name,
                url: url,
              };
            })
        );

        const imgUrls = await Promise.all(promises);

        // create new question masukkan juga hasil promises
        questionImg = imgUrls.find((img) => img.name === 'questionImg');

        questionsData.push({
          questionTitle: formData.questionTitle,
          questionIndex: formData.questionIndex,
          subTitle: formData.questionSubtitle,
          questionSlug: formData.questionSlug,
          type: formData.questionType,
          choices: {},
          questionImg: questionImg.url,
          fieldId: null,
        });
      }

      const createColumnResult = await createNewColumn(questionsData[0]);

      if (!createColumnResult.isSuccess) {
        const err = { msg: 'create new column process failed' };
        throw err;
      }

      const newQuestion = await insertRow({
        ...questionsData[0],
        fieldId: createColumnResult.fieldId,
      });

      if (newQuestion?.id) {
        Swal.fire({
          icon: 'success',
          text: 'Question has been submitted',
        });
        setFormData(formQuestion);
        setLoading(false);
        history.push('/admin/list-poll-questions');
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Failed to submit question',
      });
    }
  };

  useEffect(() => {
    if (existingData.length) {
      setFormData({ ...formData, questionIndex: existingData.length + 1 });
    }
    // eslint-disable-next-line
  }, [existingData]);

  return (
    <>
      <AlternativeHeader
        parentName='Manage Survey Form'
        name='Input Poll Question'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='10'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Poll Question</h3>
              </CardHeader>
              <CardBody>
                {status !== 'success' || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={2}>
                        <FormGroup>
                          <label className='form-control-label'>
                            Question Index
                          </label>
                          <Input
                            type='number'
                            name='questionIndex'
                            placeholder='Question Order'
                            value={formData.questionIndex}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={10}>
                        <FormGroup>
                          <label className='form-control-label'>
                            Question Title
                          </label>
                          <Input
                            type='text'
                            name='questionTitle'
                            placeholder='Insert Question'
                            value={formData.questionTitle}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label className='form-control-label'>
                        Question Sub Title *
                      </label>
                      <Input
                        type='text'
                        name='questionSubtitle'
                        placeholder='Insert Question Sub Title'
                        value={formData.questionSubtitle}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Question Slug *
                      </label>
                      <Input
                        type='text'
                        name='questionSlug'
                        placeholder='Example: insertQuestionSlug'
                        value={formData.questionSlug}
                        disabled
                      />
                    </FormGroup>
                    <div className='form-row'>
                      <Col className='mb-4' md='12'>
                        <label className='form-control-label'>
                          Question Field Type *
                        </label>
                        <Input
                          id='questionType'
                          name='questionType'
                          type='select'
                          required
                          value={formData.questionType}
                          onChange={handleChange}
                        >
                          <option value=''>(Question Field Type)</option>
                          <option value='multipleChoice'>
                            Multiple Choice
                          </option>
                          <option value='shortAnswer'>Short Answer</option>
                        </Input>
                      </Col>
                    </div>
                    {formData.questionType === 'multipleChoice' ? (
                      <>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label className='form-control-label'>
                                Choice 1
                              </label>
                              <Input
                                type='text'
                                name='choice1'
                                placeholder='Insert 1st choice'
                                value={formData.choice1}
                                onChange={handleChange}
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label className='form-control-label'>
                                Choice 2
                              </label>
                              <Input
                                type='text'
                                name='choice2'
                                placeholder='Insert 2nd choice'
                                value={formData.choice2}
                                onChange={handleChange}
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <label className='form-control-label'>
                                Choice 3
                              </label>
                              <Input
                                type='text'
                                name='choice3'
                                placeholder='Insert 3rd choice'
                                value={formData.choice3}
                                onChange={handleChange}
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    ) : formData.questionType === 'shortAnswer' ? (
                      <>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <label className='form-control-label'>
                                Question Image
                              </label>
                              <h5
                                style={{ textAlign: 'center', color: 'grey' }}
                              >
                                Size recommendation : 216 x 216 px
                              </h5>
                              <ImageUploader
                                withIcon={true}
                                withPreview={true}
                                singleImage={true}
                                buttonText='Choose Image'
                                onChange={(e) => {
                                  e.length > 0
                                    ? setImgArray((oldArr) => [
                                        ...oldArr,
                                        {
                                          name: 'questionImg',
                                          file: e[0],
                                        },
                                      ])
                                    : handleDeleteImage('questionImg');
                                }}
                                label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                                imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                maxFileSize={2097152}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputPollQuestion;
