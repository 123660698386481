/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
// core components
import AuthHeader from 'components/Headers/AuthHeader.js';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import axios from 'axios';

function Login() {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');

  const handleEmail = (e) => {
    setemail(e.target.value);
  };

  const handlePassword = (e) => {
    setpassword(e.target.value);
  };

  const getToken = async () => {
    try {
      const response = await axios.post(
        'https://p-qore-dot-pti-feedloop.et.r.appspot.com/H9Ga2uhdY1piVcr/authenticate/password',
        {
          identifier: email,
          password: password,
        }
      );
      Cookies.set('token', response.data.token);
      Cookies.set('email', email);
      window.location.assign('/');
      setemail('');
      setpassword('');
    } catch (error) {
      if (error.response.status === 401) {
        Swal.fire({
          icon: 'error',
          text: 'Wrong Username/Password',
          confirmButtonColor: '#11cdef',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getToken();
  };

  return (
    <>
      <AuthHeader title='OMG Dashboard' lead='Selalu On, Never Fade' />
      <Container className='mt--9 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Card className='bg-secondary border-0 mb-0'>
              <CardBody className='px-lg-5 py-lg-5'>
                <div className='text-center text-muted mb-4'>
                  <small>sign in with credentials</small>
                </div>
                <Form onSubmit={handleSubmit}>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className='input-group-merge input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-email-83' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Email'
                        type='email'
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        value={email}
                        onChange={handleEmail}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className='input-group-merge input-group-alternative'>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='ni ni-lock-circle-open' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Password'
                        type='password'
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        value={password}
                        onChange={handlePassword}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className='text-center'>
                    <Button className='my-4' color='info' type='submit'>
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className='mt-3'>
              <Col xs='6'>
                <a
                  className='text-light'
                  href='#pablo'
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className='text-right' xs='6'>
                <a
                  className='text-light'
                  href='/auth/register'
                  // onClick={(e) => e.preventDefault()}
                >
                  <small>Create new account</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
