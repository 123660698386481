import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../manage_beautyarticle/Input.css';

const InputQuestion = () => {
  // state
  const [formData, setFormData] = useState({
    skinType: '',
    description: '',
    img: '',
    active: false,
  });
  const [imgFile, setImgFile] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [loading, setLoading] = useState(false);

  // qore
  const client = qoreContext.useClient();
  const { insertRow } = qoreContext
    .view('allQuizSkincareResult')
    .useInsertRow();

  // history
  const history = useHistory();

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFormData({ ...formData, description: currentContentAsHTML });
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const handleChangeImage = (picture) => {
    setImgFile(picture[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const imgUrl = await client.view('allQuizSkincareResult').upload(imgFile);
      const inserted = await insertRow({
        ...formData,
        img: imgUrl,
      });

      if (inserted) {
        Swal.fire({
          icon: 'success',
          text: 'Question has been submited',
        });
        setFormData({ skinType: '', description: '', img: '', active: false });
        setLoading(false);
        history.push('/admin/list-skincare-response');
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Fail to submit response',
      });
    }
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Quiz'
        name='Input Question'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Question</h3>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Skin Type</label>
                      <Input
                        type='text'
                        name='skinType'
                        placeholder='Insert Skintype'
                        value={formData.skinType}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            skinType: e.target.value,
                          });
                        }}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Image</label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 216 x 216 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleChangeImage}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Editor
                        editorState={editorState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                        }}
                      />
                    </FormGroup>
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputQuestion;
