import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'jsoneditor-react/es/editor.min.css';

const EditPollQuestion = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const client = qoreContext.useClient();
  const { data, status, revalidate } = qoreContext
    .view('allSkincarePoll')
    .useGetRow(id);
  const { updateRow } = qoreContext.view('allSkincarePoll').useUpdateRow();

  // state
  const [formData, setFormData] = useState(null);
  const [imgsChange, setimgsChange] = useState({
    questionImg: false,
  });

  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [uploadDone, setUploadDone] = useState(true);

  useEffect(() => {
    if (data && status === 'success') {
      setFormData(data);
    }
  }, [data, status]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleMultipleChoiceChange = (e, index) => {
    var tempAnswerList = formData.choices;
    var tempChoice = tempAnswerList.choice;

    tempChoice[index][e.target.name] = e.target.value;
    console.log(tempChoice);

    setFormData({ ...formData, choices: tempAnswerList });

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleUploadImage = async (targetName, file) => {
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allQuiz')
      .upload(file)
      .then((urlImage) => {
        setFormData({ ...formData, [targetName]: urlImage });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateRow(id, {
      questionTitle: formData.questionTitle,
      questionIndex: formData.questionIndex,
      subTitle: formData.questionSubtitle,
      questionSlug: formData.questionSlug,
      type: formData.type,
      choices: formData.choices,
      questionImg: formData.questionImg,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Question has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to update question',
        });
      });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Survey Form'
        name='Edit Poll Question'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='10'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Edit Poll Question</h3>
              </CardHeader>
              <CardBody>
                {!formData || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={2}>
                        <FormGroup>
                          <label className='form-control-label'>
                            Question Index
                          </label>
                          <Input
                            type='number'
                            name='questionIndex'
                            placeholder='Question Order'
                            value={formData?.questionIndex}
                            onChange={handleChange}
                            required
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={10}>
                        <FormGroup>
                          <label className='form-control-label'>
                            Question Title
                          </label>
                          <Input
                            type='text'
                            name='questionTitle'
                            placeholder='Insert Question'
                            value={formData?.questionTitle}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label className='form-control-label'>
                        Question Sub Title *
                      </label>
                      <Input
                        type='text'
                        name='subTitle'
                        placeholder='Insert Question Sub Title'
                        value={formData?.subTitle}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Question Slug *
                      </label>
                      <Input
                        type='text'
                        name='questionSlug'
                        placeholder='Example: insertQuestionSlug'
                        value={formData?.questionSlug}
                        onChange={handleChange}
                        required
                        disabled
                      />
                    </FormGroup>
                    <div className='form-row'>
                      <Col className='mb-4' md='12'>
                        <label className='form-control-label'>
                          Question Field Type *
                        </label>
                        <Input
                          id='type'
                          name='type'
                          type='select'
                          required
                          value={formData?.type}
                          onChange={handleChange}
                        >
                          <option value=''>(Question Field Type)</option>
                          <option value='multipleChoice'>
                            Multiple Choice
                          </option>
                          <option value='shortAnswer'>Short Answer</option>
                        </Input>
                      </Col>
                    </div>
                    {formData?.type === 'multipleChoice' ? (
                      formData.choices.choice?.map((data, index) => (
                        <Row>
                          <Col>
                            <FormGroup>
                              <label className='form-control-label'>
                                Choice {index + 1}
                              </label>
                              <Input
                                type='text'
                                name='title'
                                value={data.title}
                                onChange={(e) =>
                                  handleMultipleChoiceChange(e, index)
                                }
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ))
                    ) : data.type === 'shortAnswer' ? (
                      <Row>
                        <Col>
                          <FormGroup>
                            <label className='form-control-label mb-4'>
                              Question Image
                            </label>
                            <div>
                              <img
                                style={{ maxHeight: '360px' }}
                                src={formData?.questionImg}
                                alt='choice five'
                              />
                            </div>
                          </FormGroup>
                          {imgsChange.questionImg ? (
                            <ImageUploader
                              withIcon={true}
                              withPreview={true}
                              singleImage={true}
                              buttonText='Choose Image'
                              onChange={(e) => {
                                handleUploadImage('questionImg', e[0]);
                              }}
                              label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                              imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                              maxFileSize={2097152}
                            />
                          ) : (
                            <Button
                              color='primary'
                              style={{ marginTop: '8px' }}
                              onClick={() => {
                                setimgsChange({
                                  ...imgsChange,
                                  questionImg: true,
                                });
                              }}
                            >
                              Change Question Image
                            </Button>
                          )}
                        </Col>
                      </Row>
                    ) : null}
                    <div className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={!isEdited || !uploadDone ? true : false}
                      >
                        Submit
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.push('/admin/list-poll-questions');
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditPollQuestion;
