import React, { useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const formProduct = {
  no: '',
  name: '',
  hexColor: '',
  bpom: '',
  price: 0,
  imgProduct: '',
  imgBottle: '',
  imgApplicator: '',
  imgSwatch: '',
  imgLip: '',
  imgHand: '',
  imgModel: '',
  claimImage1: '',
  claimImage2: '',
  claimImage3: '',
  claimText1: '',
  claimText2: '',
  claimText3: '',
  quizDesc: null,
  quizTitle: null,
  productDescription: '',
  productInformation: '',
  tipsPengunaan: '',
  ingredients: '',
  faq: '',
};

const InputProduct = () => {
  // state
  const [formData, setFormData] = useState(formProduct);
  const [imgArray, setImgArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tipsState, setTipsState] = useState(() =>
    EditorState.createEmpty()
  );

  const [infoState, setInfoState] = useState(() =>
    EditorState.createEmpty()
  );

  const [ingredientState, setIngredientState] = useState(() =>
    EditorState.createEmpty()
  );

  const [faqState, setFaqState] = useState(() =>
    EditorState.createEmpty()
  );

  // qore
  const client = qoreContext.useClient();
  const { insertRow } = qoreContext.view('allProducts').useInsertRow();

  // history
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDeleteImage = (deletedName) => {
    let newImages = [...imgArray];
    newImages = newImages.filter((item) => item.name !== deletedName);
    setImgArray(newImages);
  };

  const handleTipsChage = (state) => {
    setTipsState(state);

    let currentContentAsHTML = draftToHtml(
      convertToRaw(state.getCurrentContent())
    );
    setFormData({ ...formData, 'tipsPenggunaan': currentContentAsHTML });
  };

  const handleInfoChange = (state) => {
    setInfoState(state);
    
    let currentContentAsHTML = draftToHtml(
      convertToRaw(state.getCurrentContent())
    );
    setFormData({ ...formData, 'productInformation': currentContentAsHTML });
  };

  const handleIgrdtsChange = (state) => {
    setIngredientState(state);
    
    let currentContentAsHTML = draftToHtml(
      convertToRaw(state.getCurrentContent())
    );
    setFormData({ ...formData, 'ingredients': currentContentAsHTML });
  };

  const handleFAQChange = (state) => {
    setFaqState(state);
    
    let currentContentAsHTML = draftToHtml(
      convertToRaw(state.getCurrentContent())
    );
    setFormData({ ...formData, 'faq': currentContentAsHTML });
  };

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      client
        .view('allProducts')
        .upload(file)
        .then((url) => {
          resolve({ data: { link: url } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // bikin promises, upload gambar
      const promises = imgArray.map((img) =>
        client
          .view('allProducts')
          .upload(img.file)
          .then((url) => {
            return {
              name: img.name,
              url: url,
            };
          })
      );

      const imgUrls = await Promise.all(promises);

      // create new product masukkan juga hasil promises
      const product = imgUrls.find((img) => img.name === 'imgProduct');
      const bottle = imgUrls.find((img) => img.name === 'imgBottle');
      const applicator = imgUrls.find((img) => img.name === 'imgApplicator');
      const swatch = imgUrls.find((img) => img.name === 'imgSwatch');
      const lip = imgUrls.find((img) => img.name === 'imgLip');
      const hand = imgUrls.find((img) => img.name === 'imgHand');
      const model = imgUrls.find((img) => img.name === 'imgModel');
      const claim1 = imgUrls.find((img) => img.name === 'claimImage1');
      const claim2 = imgUrls.find((img) => img.name === 'claimImage2');
      const claim3 = imgUrls.find((img) => img.name === 'claimImage3');

      const newProduct = await insertRow({
        no: formData.no,
        name: formData.name,
        parentName: formData.parentName,
        hexColor: formData.hexColor,
        bpom: formData.bpom,
        price: formData.price,
        productDescription: formData.productDescription,
        imgProduct: product.url,
        imgBottle: bottle.url,
        imgApplicator: applicator.url,
        imgSwatch: swatch.url,
        imgLip: lip.url,
        imgHand: hand.url,
        imgModel: model.url,
        claimImage1: claim1.url,
        claimImage2: claim2.url,
        claimImage3: claim3.url,
        claimText1: formData.claimText1,
        claimText2: formData.claimText2,
        claimText3: formData.claimText3,
        type: 'makeup',
        active: formData.active,
        tokopedia: formData.tokopedia,
        shopee: formData.shopee,
        blibli: formData.blibli,
        lazada: formData.lazada,
        tipsPenggunaan: formData.tipsPenggunaan,
        productInformation: formData.productInformation,
        ingredients: formData.ingredients,
        faq: formData.faq,
      });

      if (newProduct) {
        Swal.fire({
          icon: 'success',
          text: 'Product has been submited',
        });
        setFormData(formProduct);
        setLoading(false);
        history.push('/admin/list-make-up');
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Fail to submit product',
      });
    }
  };

  return (
    <>
      <AlternativeHeader parentName='Manage Make Up' name='Input Make Up' />
      <Container className='mt--6' fluid>
        {loading ? (
          <div className='text-center'>
            <Spinner />
            <p>loading</p>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg='6'>
                <Card>
                  <CardHeader>
                    <h3 className='mb-0'>Main Data</h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={3}>
                        <FormGroup>
                          <label className='form-control-label'>No</label>
                          <Input
                            type='text'
                            name='no'
                            placeholder='Insert product number'
                            value={formData.no}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={9}>
                        <FormGroup>
                          <label className='form-control-label'>Product Shade</label>
                          <Input
                            type='text'
                            name='name'
                            placeholder='Insert Product Shade Name'
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label className='form-control-label'>
                        Product Name
                      </label>
                      <Input
                        type='text'
                        name='parentName'
                        placeholder='Insert Product Name'
                        value={formData.parentName}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Hexcolor (ex: #cf578a)
                      </label>
                      <Input
                        type='text'
                        name='hexColor'
                        placeholder='Insert hexcolor code'
                        value={formData.hexColor}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>BPOM</label>
                      <Input
                        type='text'
                        name='bpom'
                        placeholder='Insert BPOM number'
                        value={formData.bpom}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Price</label>
                      <Input
                        type='number'
                        name='price'
                        placeholder='Insert product price'
                        value={formData.price}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Product Description</label>
                      <Input
                        type='textarea'
                        rows='5'
                        name='productDescription'
                        placeholder='Insert product description'
                        value={formData.productDescription}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label className='form-control-label'>Shopee</label>
                          <Input
                            type='text'
                            name='shopee'
                            placeholder='insert product link on shopee'
                            value={formData.shopee}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label className='form-control-label'>
                            Tokopedia
                          </label>
                          <Input
                            type='text'
                            name='tokopedia'
                            placeholder='insert product link on tokopedia'
                            value={formData.tokopedia}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label className='form-control-label'>Lazada</label>
                          <Input
                            type='text'
                            name='lazada'
                            placeholder='insert product link on lazada'
                            value={formData.lazada}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label className='form-control-label'>Blibli</label>
                          <Input
                            type='text'
                            name='blibli'
                            placeholder='insert product link on blibli'
                            value={formData.blibli}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label className='form-control-label'>
                        Product Image
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 88 x 480 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'imgProduct',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('imgProduct');
                        }}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Bottle Image</label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 64 x 286 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'imgBottle',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('imgBottle');
                        }}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Applicator Image
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 64 x 330 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'imgApplicator',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('imgApplicator');
                        }}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Popup Image</label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 269 x 380 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'imgSwatch',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('imgSwatch');
                        }}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Product On Lip
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 400 x 400 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'imgLip',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('imgLip');
                        }}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Swatch On Hand
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 400 x 400 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'imgHand',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('imgHand');
                        }}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Product On Model
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 400 x 400 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'imgModel',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('imgModel');
                        }}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Claim Image 1
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 130 x 35 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'claimImage1',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('claimImage1');
                        }}
                        label='Max : 1 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={1048576}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Claim Text 1</label>
                      <Input
                        type='text'
                        name='claimText1'
                        placeholder='Insert Claim Text 1'
                        value={formData?.claimText1}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Claim Image 2
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 130 x 35 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'claimImage2',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('claimImage2');
                        }}
                        label='Max : 1 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={1048576}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Claim Text 2</label>
                      <Input
                        type='text'
                        name='claimText2'
                        placeholder='Insert Claim Text 2'
                        value={formData?.claimText2}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Claim Image 3
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 130 x 35 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={(e) => {
                          e.length > 0
                            ? setImgArray((oldArr) => [
                                ...oldArr,
                                {
                                  name: 'claimImage3',
                                  file: e[0],
                                },
                              ])
                            : handleDeleteImage('claimImage3');
                        }}
                        label='Max : 1 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={1048576}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Claim Text 3</label>
                      <Input
                        type='text'
                        name='claimText3'
                        placeholder='Insert Claim Text 3'
                        value={formData?.claimText3}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='exampleFormControlSelect3'
                      >
                        Status
                      </label>
                      <Input
                        id='exampleFormControlSelect3'
                        type='select'
                        name='active'
                        onChange={handleChange}
                      >
                        <option value='false'>NON ACTIVE</option>
                        <option value='true'>ACTIVE</option>
                      </Input>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col lg='6'>
                <Card>
                  <CardHeader>
                    <h3 className='mb-0'>Supporting Data</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <label className='form-control-label'>Usage Tips</label>
                      <Editor
                        editorState={tipsState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        onEditorStateChange={handleTipsChage}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'image',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: uploadImageCallBack,
                            alt: { present: true, mandatory: true },
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Product Information</label>
                      <Editor
                        editorState={infoState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        onEditorStateChange={handleInfoChange}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'image',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: uploadImageCallBack,
                            alt: { present: true, mandatory: true },
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Ingredients</label>
                      <Editor
                        editorState={ingredientState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        onEditorStateChange={handleIgrdtsChange}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'image',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: uploadImageCallBack,
                            alt: { present: true, mandatory: true },
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        FAQ
                      </label>
                      <Editor
                        editorState={faqState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        onEditorStateChange={handleFAQChange}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'image',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: uploadImageCallBack,
                            alt: { present: true, mandatory: true },
                          },
                        }}
                      />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className='mb-5'>
                <div className='text-right'>
                  <Button type='submit' color='primary'>
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
};

export default InputProduct;
