import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditBeautyTutorial = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const { data, status, revalidate } = qoreContext
    .view('allBeautyTutorial')
    .useGetRow(id);
  const { updateRow } = qoreContext.view('allBeautyTutorial').useUpdateRow();

  // state
  const [formTutorial, setFormTutorial] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    if (data && status === 'success') {
      setFormTutorial(data);
    }
  }, [data, status]);

  const handleChange = (e) => {
    setFormTutorial({ ...formTutorial, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateRow(id, {
      name: formTutorial?.name,
      link: formTutorial?.link,
      active: formTutorial?.active,
      index: formTutorial?.index,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Home banner has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to update home banner',
        });
      });
  };

  const handleChangeActive = (e) => {
    setIsEdited(true);
    let status = false;
    if (e.target.value === 'true') {
      status = true;
    } else {
      status = false;
    }
    setFormTutorial({ ...formTutorial, active: status });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Tutorial'
        name='Edit Beauty Tutorial'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card>
              <CardHeader>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Manage Beauty Tutorial</h3>
                  </Col>
                  <Col className='text-right' xs='4'>
                    <Button
                      color='primary'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                      size='sm'
                    >
                      Change Name
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {!formTutorial || loading ? (
                <div className='text-center'>
                  <Spinner />
                  <p>loading</p>
                </div>
              ) : (
                <CardBody>
                  <Form>
                    <h6 className='heading-small text-muted mb-4'>
                      Edit Beauty Tutorial
                    </h6>
                    <div className='pl-lg-4'>
                      <Row>
                        <Col lg='6'>
                          <FormGroup>
                            <label className='form-control-label'>Index</label>
                            <Input
                              type='number'
                              name='index'
                              placeholder='Index of Home Banner'
                              value={formTutorial?.index}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-country'
                            >
                              Beauty Tutorial Name
                            </label>
                            <Input
                              defaultValue=''
                              id='input-country'
                              placeholder='Name here'
                              type='text'
                              name='name'
                              value={formTutorial?.name}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <h6 className='heading-small text-muted mb-4'>
                        Beauty Tutorial Details
                      </h6>
                      <div className='pl-lg-4'>
                        <Row>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-country'
                              >
                                Youtube Link
                              </label>
                              <Input
                                type='url'
                                name='link'
                                defaultValue=''
                                id='input-country'
                                placeholder='Link here'
                                value={formTutorial?.link}
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='3'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='exampleFormControlSelect3'
                              >
                                Beauty Tutorials Active
                              </label>
                              <Input
                                id='exampleFormControlSelect3'
                                type='select'
                                name='active'
                                value={formTutorial?.active}
                                onChange={handleChangeActive}
                              >
                                <option value='false'>NON ACTIVE</option>
                                <option value='true'>ACTIVE</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col lg='12'>
                            <div className='text-right'>
                              <Button
                                type='submit'
                                color='primary'
                                disabled={!isEdited ? true : false}
                                onClick={handleSubmit}
                              >
                                Submit
                              </Button>
                              <Button
                                type='button'
                                color='secondary'
                                onClick={() => {
                                  history.goBack();
                                }}
                              >
                                Back
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditBeautyTutorial;
