import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const EditQuestion = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const client = qoreContext.useClient();
  const { data, status, revalidate } = qoreContext
    .view('allQuiz')
    .useGetRow(id);
  const { updateRow } = qoreContext.view('allQuiz').useUpdateRow();

  // state
  const [formData, setFormData] = useState(null);
  const [imgsChange, setimgsChange] = useState({
    imgOne: false,
    imgTwo: false,
    imgThree: false,
    imgFour: false,
    imgFive: false,
  });

  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [uploadDone, setUploadDone] = useState(true);

  useEffect(() => {
    if (data && status === 'success') {
      setFormData(data);
    }
  }, [data, status]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleUploadImage = async (targetName, file) => {
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allQuiz')
      .upload(file)
      .then((urlImage) => {
        setFormData({ ...formData, [targetName]: urlImage });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateRow(id, {
      question: formData.question,
      choice1: formData.choice1,
      choice2: formData.choice2,
      choice3: formData.choice3,
      choice4: formData.choice4,
      choice5: formData.choice5,
      answer1: formData.answer1,
      answer2: formData.answer2,
      answer3: formData.answer3,
      answer4: formData.answer4,
      answer5: formData.answer5,
      imgOne: formData.imgOne,
      imgTwo: formData.imgTwo,
      imgThree: formData.imgThree,
      imgFour: formData.imgFour,
      imgFive: formData.imgFive,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Question has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to update question',
        });
      });
  };

  return (
    <>
      <AlternativeHeader parentName='Manage Beauty Quiz' name='Edit Question' />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='10'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Edit Question</h3>
              </CardHeader>
              <CardBody>
                {!formData || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Question</label>
                      <Input
                        type='text'
                        name='question'
                        placeholder='Insert question'
                        value={formData?.question}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Choice 1</label>
                          <Input
                            type='text'
                            name='choice1'
                            placeholder='Insert 1st choice'
                            value={formData?.choice1}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Point 1</label>
                          <Editor
                            value={formData?.answer1}
                            onChange={(e) => {
                              setFormData({ ...formData, answer1: e });
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Image 1</label>
                          <div>
                            <img
                              style={{ maxHeight: '240px' }}
                              src={formData?.imgOne}
                              alt='choice one'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        {imgsChange.imgOne ? (
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              handleUploadImage('imgOne', e[0]);
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        ) : (
                          <Button
                            color='primary'
                            style={{ marginTop: '8px' }}
                            onClick={() => {
                              setimgsChange({ ...imgsChange, imgOne: true });
                            }}
                          >
                            Change Image One
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Choice 2</label>
                          <Input
                            type='text'
                            name='choice2'
                            placeholder='Insert 2nd choice'
                            value={formData?.choice2}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Point 2</label>
                          <Editor
                            value={formData?.answer2}
                            onChange={(e) => {
                              setFormData({ ...formData, answer2: e });
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Image 2</label>
                          <div>
                            <img
                              style={{ maxHeight: '240px' }}
                              src={formData?.imgTwo}
                              alt='choice two'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        {imgsChange.imgTwo ? (
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              handleUploadImage('imgTwo', e[0]);
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        ) : (
                          <Button
                            color='primary'
                            style={{ marginTop: '8px' }}
                            onClick={() => {
                              setimgsChange({ ...imgsChange, imgTwo: true });
                            }}
                          >
                            Change Image Two
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Choice 3</label>
                          <Input
                            type='text'
                            name='choice3'
                            placeholder='Insert 3rd choice'
                            value={formData?.choice3}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Point 3</label>
                          <Editor
                            value={formData?.answer3}
                            onChange={(e) => {
                              setFormData({ ...formData, answer3: e });
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Image 3</label>
                          <div>
                            <img
                              style={{ maxHeight: '240px' }}
                              src={formData?.imgThree}
                              alt='choice three'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        {imgsChange.imgThree ? (
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              handleUploadImage('imgThree', e[0]);
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        ) : (
                          <Button
                            color='primary'
                            style={{ marginTop: '8px' }}
                            onClick={() => {
                              setimgsChange({ ...imgsChange, imgThree: true });
                            }}
                          >
                            Change Image Three
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Choice 4</label>
                          <Input
                            type='text'
                            name='choice4'
                            placeholder='Insert 4th choice'
                            value={formData?.choice4}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Point 4</label>
                          <Editor
                            value={formData?.answer4}
                            onChange={(e) => {
                              setFormData({ ...formData, answer4: e });
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Image 4</label>
                          <div>
                            <img
                              style={{ maxHeight: '240px' }}
                              src={formData?.imgFour}
                              alt='choice four'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        {imgsChange.imgFour ? (
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              handleUploadImage('imgFour', e[0]);
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        ) : (
                          <Button
                            color='primary'
                            style={{ marginTop: '8px' }}
                            onClick={() => {
                              setimgsChange({ ...imgsChange, imgFour: true });
                            }}
                          >
                            Change Image Four
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Choice 5</label>
                          <Input
                            type='text'
                            name='choice5'
                            placeholder='Insert 5th choice'
                            value={formData?.choice5}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Point 5</label>
                          <Editor
                            value={formData?.answer5}
                            onChange={(e) => {
                              setFormData({ ...formData, answer5: e });
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Image 5</label>
                          <div>
                            <img
                              style={{ maxHeight: '360px' }}
                              src={formData?.imgFive}
                              alt='choice five'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        {imgsChange.imgFive ? (
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              handleUploadImage('imgFive', e[0]);
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        ) : (
                          <Button
                            color='primary'
                            style={{ marginTop: '8px' }}
                            onClick={() => {
                              setimgsChange({ ...imgsChange, imgFive: true });
                            }}
                          >
                            Change Image Five
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <div className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={!isEdited || !uploadDone ? true : false}
                      >
                        Submit
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.push('/admin/list-question');
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditQuestion;
