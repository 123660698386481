import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import randomString from 'randomstring';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Input.css';

const formArticle = {
  name: `BT-${randomString.generate(4)}`,
  title: '',
  details: '',
  publish: false,
};

const InputBeautyArticle = () => {
  // state
  const [formData, setFormData] = useState(formArticle);
  const [imgArticle, setImgArticle] = useState('');
  const [imgWebp, setImgWebp] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [loading, setLoading] = useState(false);

  // qore
  const client = qoreContext.useClient();
  const { insertRow } = qoreContext.view('allBeautyTips').useInsertRow();
  const { updateRow } = qoreContext.view('allBeautyTips').useUpdateRow();
  const { data: allArticles, status } = qoreContext
    .view('allBeautyTips')
    .useListRow({});

  // history
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImage = (picture) => {
    setImgArticle(picture[0]);
  };

  const handleWebp = (picture) => {
    setImgWebp(picture[0]);
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFormData({ ...formData, details: currentContentAsHTML });
  };

  const convertToSlug = (title) => {
    const slug = title
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
    return slug;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const errorMessage = {
        code: 400,
        message: 'terjadi kesalahan pada server',
      };

      const articleResponse = await insertRow({
        ...formData,
        slug: convertToSlug(formData.title),
      });

      let imageUrl = '';
      let webpUrl = '';

      if (imgArticle) {
        imageUrl = await client.view('allBeautyTips').upload(imgArticle);
      }

      if (imgWebp) {
        webpUrl = await client.view('allBeautyTips').upload(imgWebp);
      }

      const idArticle = articleResponse.id;
      const updated = await updateRow(idArticle, {
        img: imageUrl,
        imgWebp: webpUrl,
      });

      if (!updated.id) throw errorMessage;

      Swal.fire({
        icon: 'success',
        text: 'Beauty article has been submited',
      });
      setFormData(formArticle);
      setLoading(false);
      history.push('/admin/list-beauty-tips');
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        text: error.message || 'Fail to submit beauty article',
      });
      setLoading(false);
    }
  };

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      client
        .view('allBeautyTips')
        .upload(file)
        .then((url) => {
          resolve({ data: { link: url } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  useEffect(() => {
    if (allArticles.length > 0) {
      setFormData((formData) => ({
        ...formData,
        index: allArticles.length + 1,
      }));
    }
  }, [allArticles]);

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Tips'
        name='Input Beauty Tips'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='12'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Beauty Tips</h3>
              </CardHeader>
              <CardBody>
                {loading || status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Index</label>
                      <Input
                        type='number'
                        name='index'
                        placeholder='Index of Make Up Banner'
                        value={formData.index}
                        onChange={handleChange}
                        disabled={true}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Title</label>
                      <Input
                        type='text'
                        name='title'
                        placeholder='Insert title'
                        value={formData.title}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Poster Image</label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 1128 x 1504 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleImage}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Article Image (.webp Format)
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 277 x 370 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleWebp}
                        label='Max : 2 MB, accepted: webp'
                        imgExtension={['.webp']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Details</label>
                      <Editor
                        editorState={editorState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'image',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: uploadImageCallBack,
                            alt: { present: true, mandatory: true },
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='exampleFormControlSelect3'
                      >
                        Status
                      </label>
                      <Input
                        id='exampleFormControlSelect3'
                        type='select'
                        name='publish'
                        onChange={handleChange}
                      >
                        <option value='false'>HIDDEN</option>
                        <option value='true'>PUBLISH</option>
                      </Input>
                    </FormGroup>
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputBeautyArticle;
