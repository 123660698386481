import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import Swal from 'sweetalert2';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../manage_beautyarticle/Input.css';

const EditBeautyArticle = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const client = qoreContext.useClient();
  const { data, status, revalidate } = qoreContext
    .view('allQuizSkincareResult')
    .useGetRow(id);
  const { updateRow } = qoreContext
    .view('allQuizSkincareResult')
    .useUpdateRow();

  // state
  const [formData, setFormData] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isImgChange, setIsImgChange] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const convertHtmlToDraft = (articleHTML) => {
    let contentBlock = htmlToDraft(articleHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const article = EditorState.createWithContent(contentState);
      setEditorState(article);
    }
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFormData({ ...formData, description: currentContentAsHTML });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleChangeImage = (picture) => {
    setImgFile(picture[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updatePayload = { ...formData };
      delete updatePayload.id;
      if (imgFile) {
        const newImgUrl = await client
          .view('allQuizSkincareResult')
          .upload(imgFile);
        if (newImgUrl) {
          updatePayload.img = newImgUrl;
        }
      }

      const updated = await updateRow(id, updatePayload);

      if (updated) {
        Swal.fire({
          icon: 'success',
          text: 'Response has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: 'error',
        text: 'Fail to update response',
      });
    }
  };

  useEffect(() => {
    if (data && status === 'success') {
      setFormData(data);
      convertHtmlToDraft(data.description);
    }
  }, [data, status]);

  return (
    <>
      <AlternativeHeader
        parentName='Manage Skincare Quiz'
        name='Edit Skincare Response'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Edit Skincare Response</h3>
              </CardHeader>
              <CardBody>
                {status !== 'success' || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Skin Type</label>
                      <Input
                        type='text'
                        name='skinType'
                        placeholder='Input Skin Type'
                        value={formData?.skinType}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Image</label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={formData?.img}
                          alt='article'
                        />
                      </div>
                      {isImgChange ? (
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={handleChangeImage}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      ) : (
                        <Button
                          color='primary'
                          style={{ marginTop: '8px' }}
                          onClick={() => {
                            setIsImgChange(true);
                          }}
                        >
                          Change Image
                        </Button>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Editor
                        editorState={editorState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        onEditorStateChange={handleEditorChange}
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                        }}
                      />
                    </FormGroup>
                    <div className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={!isEdited}
                      >
                        Submit
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.push('/admin/list-skincare-response');
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditBeautyArticle;
