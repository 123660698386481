import { QoreClient } from '@feedloop/qore-client';
import createQoreContext from '@feedloop/qore-react';
import Cookies from 'js-cookie';

const client = new QoreClient({
  endpoint: process.env.REACT_APP_QORE_ENDPOINT || '',
  projectId: process.env.REACT_APP_QORE_PROJECT_ID || '',
  organizationId: process.env.REACT_APP_QORE_ORG_ID || '',
  authenticationId: process.env.REACT_APP_QORE_AUTH_ID || '',
  onError: (error) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== '/auth/login'
    ) {
      window.location.replace('/auth/login');
    }
  },
  getToken: () => Cookies.get('token'),
});

export const qoreContext = createQoreContext(client);
