import React from 'react';

// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Button,
  CardBody,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';

// pagination
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Show{' '}
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{' '}
        entries.
      </label>
    </div>
  ),
});

// search bar
const { SearchBar } = Search;

const BeautyArticleList = () => {
  // data banners from qore
  const {
    data: articles,
    status,
    revalidate,
  } = qoreContext.view('allBeautyTips').useListRow({ '$by.index': 'asc' });

  // update row banner to qore
  const { updateRow: updateArticle } = qoreContext
    .view('allBeautyTips')
    .useUpdateRow();

  const handleUpdateStatus = (status, idArticle, imgArticle) => {
    if (imgArticle) {
      Swal.fire({
        title: `Are you sure to ${status ? 'hide' : 'publish'} this Article?`,
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          updateArticle(idArticle, { publish: !status })
            .then(() => {
              revalidate();

              Swal.fire({
                icon: 'success',
                text: 'Article Status has been updated.',
              });
            })
            .catch((error) => {
              console.log(error.response);

              Swal.fire({
                icon: 'error',
                text: 'Fail to update Article status (Server Error)',
              });
            });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Mohon upload image article terlebih dahulu',
      });
    }
  };

  const BannerStatus = (status, id, imgArticle) => {
    if (status) {
      return (
        <Button
          color='success'
          onClick={() => {
            handleUpdateStatus(status, id, imgArticle);
          }}
          title='click to hide'
        >
          <span>Published</span>
        </Button>
      );
    } else {
      return (
        <Button
          color='danger'
          onClick={() => {
            handleUpdateStatus(status, id, imgArticle);
          }}
          title='click to publish'
        >
          <span>Hidden</span>
        </Button>
      );
    }
  };

  const handleUp = async (inputIndex, currentID) => {
    if (inputIndex !== 1) {
      const currentIndex = inputIndex - 1;

      try {
        const prevBanner = articles.find(
          (banner) => banner.index === currentIndex
        );

        const currentRowUpdated = await updateArticle(currentID, {
          index: currentIndex,
        });
        const prevRowUpdated = await updateArticle(prevBanner.id, {
          index: inputIndex,
        });

        if (currentRowUpdated && prevRowUpdated) {
          revalidate();
          Swal.fire({
            icon: 'success',
            text: ' index has been updated',
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          text: 'Fail to edit index',
        });
      }
    } else {
      return null;
    }
  };

  const handleDown = async (inputIndex, currentID) => {
    if (inputIndex !== articles.length) {
      const currentIndex = inputIndex + 1;

      try {
        const nexBanner = articles.find(
          (banner) => banner.index === currentIndex
        );

        const currentRowUpdated = await updateArticle(currentID, {
          index: currentIndex,
        });
        const nextRowUpdated = await updateArticle(nexBanner.id, {
          index: inputIndex,
        });

        if (currentRowUpdated && nextRowUpdated) {
          revalidate();
          Swal.fire({
            icon: 'success',
            text: ' index has been updated',
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          text: 'Fail to edit index',
        });
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Tips'
        name='Beauty Tips List'
      />
      <Container className='mt--6' fluid>
        <Row>
          <div className='col'>
            <div className='card-wrapper'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Beauty Tips List</h3>
                </CardHeader>
                {status === 'success' ? (
                  <ToolkitProvider
                    data={articles}
                    keyField='banners'
                    columns={[
                      {
                        dataField: 'name',
                        text: 'ID',
                        sort: true,
                      },
                      {
                        dataField: 'title',
                        text: 'Title',
                        sort: true,
                      },
                      {
                        editable: false,
                        text: 'Image',
                        dataField: 'img',
                        formatter: (cell, row) => {
                          return (
                            <img
                              src={
                                row.img ||
                                'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png'
                              }
                              style={{ maxHeight: '120px' }}
                              alt='article'
                            />
                          );
                        },
                      },
                      {
                        editable: false,
                        text: 'Webp Image',
                        dataField: 'imgWebp',
                        formatter: (cell, row) => {
                          return (
                            <img
                              src={
                                row?.imgWebp ||
                                'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png'
                              }
                              style={{ maxHeight: '120px' }}
                              alt='article'
                            />
                          );
                        },
                      },
                      {
                        editable: false,
                        text: 'Webp Image',
                        dataField: 'imgWebp',
                        formatter: (cell, row) => {
                          return (
                            <img
                              src={
                                row?.imgWebp ||
                                'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png'
                              }
                              style={{ maxHeight: '120px' }}
                              alt='article'
                            />
                          );
                        },
                      },
                      {
                        editable: false,
                        text: 'Webp Image',
                        dataField: 'imgWebp',
                        formatter: (cell, row) => {
                          return (
                            <img
                              src={
                                row?.imgWebp ||
                                'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png'
                              }
                              style={{ maxHeight: '120px' }}
                              alt='article'
                            />
                          );
                        },
                      },
                      {
                        dataField: 'createdAt',
                        text: 'Create Date',
                        sort: true,
                        formatter: (cell, row) => {
                          return (
                            <>
                              {moment(row.createdAt).format(
                                'DD MMM YYYY hh:mm:ss'
                              )}
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'updatedAt',
                        text: 'Updated Date',
                        sort: true,
                        formatter: (cell, row) => {
                          return (
                            <>
                              {moment(row.updatedAt).format(
                                'DD MMM YYYY hh:mm:ss'
                              )}
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'publish',
                        editable: false,
                        text: 'Status',
                        formatter: (cell, row) => {
                          return (
                            <>{BannerStatus(row.publish, row.id, row.img)}</>
                          );
                        },
                      },
                      {
                        dataField: 'id',
                        editable: false,
                        text: 'Action',
                        formatter: (cell, row) => {
                          return (
                            <>
                              <Button
                                color='primary'
                                title='up'
                                onClick={() => {
                                  handleUp(row?.index, row?.id);
                                }}
                                disabled={row?.index === 1}
                              >
                                <i className='fa fa-chevron-up' />
                              </Button>
                              <Button
                                color='primary'
                                title='down'
                                onClick={() => {
                                  handleDown(row?.index, row?.id);
                                }}
                                disabled={row?.index === articles.length}
                              >
                                <i className='fa fa-chevron-down' />
                              </Button>
                              <Link
                                className='btn btn-info'
                                title='More info'
                                to={`/admin/detail-beauty-tips/${row.id}`}
                              >
                                <i className='fa fa-info-circle' />
                              </Link>
                              <Link
                                className='btn btn-secondary'
                                title='Edit'
                                to={`/admin/edit-beauty-tips/${row.id}`}
                              >
                                <i className='fas fa-user-edit' />
                              </Link>
                            </>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className='py-4 table-responsive'>
                        <div
                          id='datatable-basic_filter'
                          className='dataTables_filter px-4 pb-1'
                        >
                          <label>
                            Search:
                            <SearchBar
                              className='form-control-sm'
                              placeholder=''
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField='id'
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <CardBody>
                    <div className='text-center' style={{ minHeight: '50vh' }}>
                      <Spinner />
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BeautyArticleList;
