import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../manage_beautyarticle/Input.css';

const formResponse = {
  quizTitle: '',
  quizDesc: '',
};

const InputResponse = () => {
  // state
  const [formData, setFormData] = useState(formResponse);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [loading, setLoading] = useState(false);
  const [productChoice, setProductChoice] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  // qore
  const { data: products } = qoreContext.view('allProducts').useListRow({});
  const { updateRow } = qoreContext.view('allProducts').useUpdateRow();

  // history
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (e) => {
    const selected = productChoice.filter(
      (product) => product.id === e.target.value
    );

    setSelectedProduct(selected[0]);
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFormData({ ...formData, quizDesc: currentContentAsHTML });
  };

  useEffect(() => {
    if (products) {
      const filtered = products.filter(
        (product) => !product.quizTitle && !product.quizDesc
      );
      setProductChoice(filtered);
    }
  }, [products]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await updateRow(selectedProduct.id, formData);
      Swal.fire({
        icon: 'success',
        text: 'Quiz response has been submited',
      });
      setFormData(formResponse);
      setLoading(false);
      history.push('/admin/list-response');
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        text: 'Fail to submit quiz response',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Quiz'
        name='Input Quiz Response'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Quiz Response</h3>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Product</label>
                      <Input
                        type='select'
                        id='exampleSelect'
                        name='title'
                        onChange={handleSelect}
                        value={selectedProduct}
                      >
                        <option value='' selected disabled>
                          Choose Product
                        </option>
                        {productChoice?.map((product, idx) => (
                          <option
                            value={product.id}
                            key={idx}
                          >{`${product.no} ${product.name}`}</option>
                        ))}
                      </Input>
                    </FormGroup>
                    {selectedProduct && (
                      <>
                        <FormGroup>
                          <label className='form-control-label'>
                            Popup Image
                          </label>
                          <div>
                            <img
                              style={{ maxHeight: '360px' }}
                              src={selectedProduct?.imgSwatch}
                              alt='Popup product'
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <label className='form-control-label'>
                            Model Image
                          </label>
                          <div>
                            <img
                              style={{ maxHeight: '360px' }}
                              src={selectedProduct?.imgModel}
                              alt='Model product'
                            />
                          </div>
                        </FormGroup>
                      </>
                    )}
                    <FormGroup>
                      <label className='form-control-label'>Title</label>
                      <Input
                        type='text'
                        name='quizTitle'
                        placeholder='Title of Article'
                        value={formData.quizTitle}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Editor
                        editorState={editorState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                        }}
                      />
                    </FormGroup>
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputResponse;
