import React, { useEffect, useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const DetailBeautyTutorial = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const { data, status } = qoreContext.view('allBeautyTutorial').useGetRow(id);
  const { deleteRow } = qoreContext.view('allBeautyTutorial').useDeleteRow();

  //state
  const [beautyTutorialActive, setBeautyTutorialActive] = useState(null);

  useEffect(() => {
    if (data && status === 'success') {
      if (data.status) {
        setBeautyTutorialActive('ACTIVE');
      }
      setBeautyTutorialActive('NON ACTIVE');
    }
  }, [data, status]);

  useEffect(() => {
    console.log(beautyTutorialActive);
  }, [beautyTutorialActive]);

  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you sure to DELETE this banner?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRow(id)
          .then(() => {
            Swal.fire({
              icon: 'success',
              text: 'Home banner has been deleted.',
            });
            history.goBack();
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: 'Fail to update request status (Server Error)',
            });
            console.error(error);
          });
      }
    });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Tutorial'
        name='Details Beauty Tutorial'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card>
              <CardHeader>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Manage Beauty Tutorial</h3>
                  </Col>
                  <Col className='text-right' xs='4'>
                    <Button
                      color='primary'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                      size='sm'
                    >
                      Change Name
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {status !== 'success' ? (
                <div className='text-center'>
                  <Spinner />
                  <p>loading</p>
                </div>
              ) : (
                <CardBody>
                  <Form>
                    <h6 className='heading-small text-muted mb-4'>
                      Edit Beauty Tutorial
                    </h6>
                    <div className='pl-lg-4'>
                      <Row>
                        <Col lg='6'>
                          <FormGroup>
                            <label className='form-control-label'>Index</label>
                            <Input
                              type='number'
                              name='index'
                              placeholder='Index'
                              value={data?.index}
                              readOnly
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-country'
                            >
                              Beauty Tutorial Name
                            </label>
                            <Input
                              defaultValue=''
                              id='input-country'
                              placeholder='Name here'
                              type='text'
                              name='name'
                              value={data?.name}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <h6 className='heading-small text-muted mb-4'>
                        Beauty Tutorial Details
                      </h6>
                      <div className='pl-lg-4'>
                        <Row>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-country'
                              >
                                Youtube Link
                              </label>
                              <Input
                                type='url'
                                name='link'
                                defaultValue=''
                                id='input-country'
                                placeholder='Link here'
                                value={data?.link}
                                readOnly
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='3'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='exampleFormControlSelect3'
                              >
                                Beauty Tutorials Active
                              </label>
                              <Input
                                id='exampleFormControlSelect3'
                                type='text'
                                name='active'
                                value={beautyTutorialActive}
                                readOnly
                              ></Input>
                            </FormGroup>
                          </Col>

                          <Col lg='12'>
                            <div className='text-right'>
                              <Button
                                type='button'
                                color='primary'
                                onClick={() => {
                                  history.push(`/admin/beauty-tutorial-list`);
                                }}
                              >
                                Back
                              </Button>
                              <Button
                                type='button'
                                color='dark'
                                onClick={() => {
                                  history.push(
                                    `/admin/edit-beauty-tutorial/${id}`
                                  );
                                }}
                                outline
                              >
                                Edit
                              </Button>
                              <Button
                                type='button'
                                color='danger'
                                onClick={handleDelete}
                                outline
                              >
                                Delete
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailBeautyTutorial;
