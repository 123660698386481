import React, { useEffect, useState } from 'react';

// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  FormGroup,
  Input,
  Spinner,
  Button,
  CardBody,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import Swal from 'sweetalert2';
import StarRatings from 'react-star-ratings';
import moment from 'moment';

// pagination
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Show{' '}
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{' '}
        entries.
      </label>
    </div>
  ),
});

// search bar
const { SearchBar } = Search;

const BeautyTutorialList = () => {
  // data productReview from qore
  const {
    data: productReview,
    status,
    revalidate,
  } = qoreContext.view('allProductReview').useListRow({});

  //state
  const [productReviewFiltered, setProductReviewFiltered] = useState([]);

  useEffect(() => {
    setProductReviewFiltered(productReview);
  }, [productReview]);

  // update row banner to qore
  const { updateRow: updateStatus } = qoreContext
    .view('allProductReview')
    .useUpdateRow();

  const ProductRating = (rating) => {
    if (rating >= 4) {
      return (
        <StarRatings
          rating={rating}
          starRatedColor='#22bb33'
          numberOfStars={5}
          name='rating'
          starDimension='10px'
          starSpacing='0px'
        />
      );
    } else {
      return (
        <StarRatings
          rating={rating}
          starRatedColor='#f0ad4e'
          numberOfStars={5}
          name='rating'
          starDimension='10px'
          starSpacing='0px'
        />
      );
    }
  };

  const ProductNaming = (productsData) => {
    console.log(productsData);
    let makeupCode = productsData.products.displayField
    if (makeupCode === "01" || makeupCode === "02"
    || makeupCode === "03" || makeupCode === "04"
    || makeupCode === "05" || makeupCode === "06"
    || makeupCode === "07" || makeupCode === "08"
    || makeupCode === "09" || makeupCode === "10") {
      return `Matte Kiss Lip Cream ${makeupCode} ${productsData.productsLookup}`;
    } else {
      return productsData.productsLookup;
    }
  };

  const handleUpdateStatus = (status, idProduct) => {
    Swal.fire({
      title: `Are you sure to ${
        status ? 'deactivate' : 'activate'
      } this Product Review?`,
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus(idProduct, { show: !status })
          .then((responseUpdate) => {
            revalidate();

            Swal.fire({
              icon: 'success',
              text: 'Product Review Status has been updated.',
            });
          })
          .catch((error) => {
            console.log(error.response);

            Swal.fire({
              icon: 'error',
              text: 'Fail to update Product Review status (Server Error)',
            });
          });
      }
    });
  };

  const BannerStatus = (status, id) => {
    if (status) {
      return (
        <Button
          color='success'
          onClick={() => {
            handleUpdateStatus(status, id);
          }}
          title='click to hide'
        >
          <span>Published</span>
        </Button>
      );
    } else {
      return (
        <Button
          color='danger'
          onClick={() => {
            handleUpdateStatus(status, id);
          }}
          title='click to publish'
        >
          <span>Hidden</span>
        </Button>
      );
    }
  };

  const handleChooseFilterByRating = (e) => {
    if (e.target.value === 'All') {
      setProductReviewFiltered(productReview);
    } else {
      // var productReviewFiltered = [];
      var rating;
      if (e.target.value === '1') {
        rating = 1;
      } else if (e.target.value === '2') {
        rating = 2;
      } else if (e.target.value === '3') {
        rating = 3;
      } else if (e.target.value === '4') {
        rating = 4;
      } else if (e.target.value === '5') {
        rating = 5;
      }

      const productReviewFiltered = productReview.filter(
        (product) => Number(product.rating) === rating
      );
      console.log(typeof productReviewFiltered, 'test');
      setProductReviewFiltered(productReviewFiltered);
    }
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Product Review'
        name='Product Review List'
      />
      <Container className='mt--6' fluid>
        <Row>
          <div className='col'>
            <div className='card-wrapper'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Product Review List</h3>
                </CardHeader>
                {console.log(productReviewFiltered)}
                {status === 'success' ? (
                  <ToolkitProvider
                    data={productReviewFiltered}
                    keyField='productReview'
                    columns={[
                      {
                        dataField: 'name',
                        text: 'name',
                        sort: true,
                        style: {
                          whiteSpace: 'unset',
                        },
                      },
                      {
                        dataField: 'email',
                        text: 'email',
                        sort: true,
                        style: {
                          width: '100px',
                          whiteSpace: 'unset',
                        },
                      },
                      {
                        dataField: 'productsLookup',
                        text: 'product name',
                        sort: true,
                        style: {
                          width: '100px',
                          whiteSpace: 'unset',
                        },
                        formatter: (cell, row) => {
                          return <>{ProductNaming(row)}</>;
                        },
                      },
                      {
                        dataField: 'rating',
                        text: 'rating',
                        sort: true,
                        style: {
                          width: '150px',
                          whiteSpace: 'unset',
                          textAlign: 'justify',
                        },
                        formatter: (cell, row) => {
                          return <>{ProductRating(row.rating)}</>;
                        },
                      },
                      {
                        dataField: 'title',
                        text: 'title',
                        sort: true,
                        style: {
                          width: '200px',
                          whiteSpace: 'unset',
                          textAlign: 'justify',
                        },
                      },
                      {
                        dataField: 'review',
                        text: 'review',
                        sort: true,
                        style: {
                          width: '400px',
                          whiteSpace: 'unset',
                          textAlign: 'justify',
                        },
                      },
                      {
                        dataField: 'source',
                        text: 'review source',
                        sort: true,
                        style: {
                          width: '100px',
                          whiteSpace: 'unset',
                        },
                      },
                      {
                        dataField: 'createdAt',
                        text: 'Create Date',
                        sort: true,
                        formatter: (cell, row) => {
                          return (
                            <>
                              {moment(row.createdAt).format(
                                'DD MMM YYYY hh:mm:ss'
                              )}
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'updatedAt',
                        text: 'Updated Date',
                        sort: true,
                        formatter: (cell, row) => {
                          return (
                            <>
                              {moment(row.updatedAt).format(
                                'DD MMM YYYY hh:mm:ss'
                              )}
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'show',
                        editable: false,
                        text: 'Status',
                        formatter: (cell, row) => {
                          return <>{BannerStatus(row.show, row.id)}</>;
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className='py-4 table-responsive'>
                        <Row>
                          <Col md='6' xl='8'>
                            <div
                              id='datatable-basic_filter'
                              className='dataTables_filter px-4 pb-1'
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className='form-control-sm'
                                  placeholder=''
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col md='6' xl='4'>
                            <Row className='px-4 pb-1'>
                              <Col md='6' style={{textAlign: "right", marginRight: "-1em"}}>
                                <label>Rating:</label>
                              </Col>
                              <Col md='6'>
                                <FormGroup
                                  className='form-control-sm'
                                  style={{ padding: '0px' }}
                                >
                                  <Input
                                    id='exampleFormControlSelect1'
                                    type='select'
                                    style={{
                                      height: '30px',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                    onClick={handleChooseFilterByRating}
                                  >
                                    <option>All</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <BootstrapTable
                          {...props.baseProps}
                          keyField='id'
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <CardBody>
                    <div className='text-center' style={{ minHeight: '50vh' }}>
                      <Spinner />
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BeautyTutorialList;
