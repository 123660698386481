import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import Swal from 'sweetalert2';

const EditContentSubmission = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const client = qoreContext.useClient();
  const { data, status, revalidate } = qoreContext
    .view('allContentSubmission')
    .useGetRow(id);
  const { updateRow } = qoreContext.view('allContentSubmission').useUpdateRow();

  // state
  const [formContent, setFormContent] = useState(null);
  const [formContentTemplate, setFormContentTemplate] = useState(null);
  const [changeImage, setChangeImage] = useState(false);
  const [changeWebp, setChangeWebp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [uploadDone, setUploadDone] = useState(true);

  useEffect(() => {
    if (data && status === 'success') {
      setFormContent(data);
    }
  }, [data, status]);

  useEffect(() => {
    if (formContent?.template) {
      setFormContentTemplate('YES');
    } else {
      setFormContentTemplate('NO');
    }
  }, [formContent]);

  const handleChange = (e) => {
    setFormContent({ ...formContent, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleChangeImage = async (picture) => {
    const imageDesktop = picture[0];
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allContentSubmission')
      .upload(imageDesktop)
      .then((responseDesktop) => {
        setFormContent({ ...formContent, img: responseDesktop });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  const handleWebp = async (picture) => {
    const imageArticle = picture[0];
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allContentSubmission')
      .upload(imageArticle)
      .then((responseUpload) => {
        setFormContent({ ...formContent, imgWebp: responseUpload });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  const handleChangeActive = (e) => {
    setIsEdited(true);
    let status = false;
    if (e.target.value === 'true') {
      status = true;
    } else {
      status = false;
    }
    setFormContent({ ...formContent, active: status });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateRow(id, {
      name: formContent?.name,
      description: formContent?.description,
      link: formContent?.link,
      img: formContent?.img,
      imgWebp: formContent?.imgWebp,
      active: formContent?.active,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Content Submission has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to update Content Submission',
        });
      });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Content Submission'
        name='Edit Content Submission'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card>
              <CardHeader>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Input Content Submission</h3>
                  </Col>
                  <Col className='text-right' xs='4'>
                    <Button
                      color='primary'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                      size='sm'
                    >
                      Change Name
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {status !== 'success' || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Name</label>
                      <Input
                        type='text'
                        name='name'
                        placeholder='Description of Content Submission'
                        value={formContent?.name}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Input
                        type='text'
                        name='description'
                        placeholder='Description of Content Submission'
                        value={formContent?.description}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Link</label>
                      <Input
                        type='url'
                        name='link'
                        placeholder='https://www.google.co.id'
                        value={formContent?.link}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Image Content
                      </label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={formContent?.img}
                          alt='content'
                        />
                      </div>
                      {changeImage ? (
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={handleChangeImage}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      ) : (
                        <Button
                          color='primary'
                          style={{ marginTop: '8px' }}
                          onClick={() => {
                            setChangeImage(true);
                          }}
                        >
                          Change Image
                        </Button>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Article Image (webp Format)
                      </label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={
                            formContent?.imgWebp ||
                            'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png'
                          }
                          alt='article'
                        />
                      </div>
                      {changeWebp ? (
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={handleWebp}
                          label='Max : 2 MB, accepted: webp'
                          imgExtension={['.webp']}
                          maxFileSize={2097152}
                        />
                      ) : (
                        <Button
                          color='primary'
                          style={{ marginTop: '8px' }}
                          onClick={() => {
                            setChangeWebp(true);
                          }}
                        >
                          Change Article Image
                        </Button>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='exampleFormControlSelect3'
                      >
                        Content Submission From Marketing / OMG CMS Dashboard
                      </label>
                      <Input
                        id='exampleFormControlSelect3'
                        type='text'
                        name='active'
                        value={formContentTemplate}
                        readOnly
                      ></Input>
                    </FormGroup>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='exampleFormControlSelect3'
                      >
                        Content Submission Active
                      </label>
                      <Input
                        id='exampleFormControlSelect3'
                        type='select'
                        name='active'
                        value={formContent?.active}
                        onChange={handleChangeActive}
                      >
                        <option value='false'>NON ACTIVE</option>
                        <option value='true'>ACTIVE</option>
                      </Input>
                    </FormGroup>
                    <div className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={!isEdited || !uploadDone ? true : false}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditContentSubmission;
