import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import randomString from 'randomstring';

const formBanner = {
  name: `MB-${randomString.generate(3)}`,
  description: '',
  index: 0,
  category: 'Make Up',
  status: false,
};

const InputMakeUpBanner = () => {
  // state
  const [formData, setFormData] = useState(formBanner);
  const [imgDesktop, setImgDesktop] = useState('');
  const [imgMobile, setImgMobile] = useState('');
  const [loading, setLoading] = useState(false);

  // qore
  const client = qoreContext.useClient();
  const { insertRow } = qoreContext.view('allCategoryBanner').useInsertRow();
  const { updateRow } = qoreContext.view('allCategoryBanner').useUpdateRow();
  const { data: allBanners, status } = qoreContext
    .view('allCategoryBanner')
    .useListRow({ category: 'Make Up' });

  // history
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageDesktop = (picture) => {
    setImgDesktop(picture[0]);
  };

  const handleImageMobile = (picture) => {
    setImgMobile(picture[0]);
  };

  useEffect(() => {
    if (allBanners.length > 0) {
      setFormData((formData) => ({
        ...formData,
        index: allBanners.length + 1,
      }));
    }
  }, [allBanners]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const errorMessage = {
        code: 400,
        message: 'terjadi kesalahan pada server',
      };
      const { id: idBanner } = await insertRow(formData);
      const urlDesktop = await client
        .view('allCategoryBanner')
        .upload(imgDesktop);
      const urlMobile = await client
        .view('allCategoryBanner')
        .upload(imgMobile);

      if (!idBanner || !urlDesktop || !urlMobile) throw errorMessage;

      const rowUpdated = await updateRow(idBanner, {
        imgWeb: urlDesktop,
        imgMobile: urlMobile,
      });

      if (!rowUpdated) throw errorMessage;
      Swal.fire({
        icon: 'success',
        text: 'Make up banner has been submited',
      });
      setFormData(formBanner);
      setLoading(false);
      history.push('/admin/list-make-up-banner');
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: 'error',
        text: 'Fail to submit make up banner',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Make Up Banner'
        name='Input Make Up Banner'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Make Up Banner</h3>
              </CardHeader>
              <CardBody>
                {loading || status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Index</label>
                      <Input
                        type='number'
                        name='index'
                        placeholder='Index of Make Up Banner'
                        value={formData.index}
                        onChange={handleChange}
                        disabled={true}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Input
                        type='text'
                        name='description'
                        placeholder='Description of Make Up Banner'
                        value={formData.description}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Desktop Image
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 1160 x 580 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleImageDesktop}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Mobile Image</label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 720 x 960 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleImageMobile}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='exampleFormControlSelect3'
                      >
                        Banner Status
                      </label>
                      <Input
                        id='exampleFormControlSelect3'
                        type='select'
                        name='status'
                        onChange={handleChange}
                      >
                        <option value='false'>NON ACTIVE</option>
                        <option value='true'>ACTIVE</option>
                      </Input>
                    </FormGroup>
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputMakeUpBanner;
