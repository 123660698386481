/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Homepage from 'views/omg_pages/homepage';
import Login from 'views/pages/examples/Login.js';

import InputHomeBanner from 'views/omg_pages/manage_homebanner/InputHomeBanner';
import HomeBannerList from 'views/omg_pages/manage_homebanner/HomeBannerList';
import DetailHomeBanner from 'views/omg_pages/manage_homebanner/DetailHomeBanner';
import EditHomeBanner from 'views/omg_pages/manage_homebanner/EditHomeBanner';

import InputContentSubmission from 'views/omg_pages/manage_contentsubmission/InputContentSubmission';
import ContentSubmissionList from 'views/omg_pages/manage_contentsubmission/ContentSubmissionList';
import DetailContentSubmission from 'views/omg_pages/manage_contentsubmission/DetailContentSubmission';
import EditContentSubmission from 'views/omg_pages/manage_contentsubmission/EditContentSubmission';

import InputKOLReview from 'views/omg_pages/manage_kolreview/InputKOLReview';
import KOLReviewList from 'views/omg_pages/manage_kolreview/KOLReviewList';
import DetailKOLReview from 'views/omg_pages/manage_kolreview/DetailKOLReview';
import EditKOLReview from 'views/omg_pages/manage_kolreview/EditKOLReview';

import InputBeautyTutorial from 'views/omg_pages/manage_beautytutorial/InputBeautyTutorial';
import BeautyTutorialList from 'views/omg_pages/manage_beautytutorial/BeautyTutorialList';
import DetailBeautyTutorial from 'views/omg_pages/manage_beautytutorial/DetailBeautyTutorial';
import EditBeautyTutorial from 'views/omg_pages/manage_beautytutorial/EditBeautyTutorial';

import BeautyArticleList from 'views/omg_pages/manage_beautyarticle/BeautyArticleList';
import InputBeautyArticle from 'views/omg_pages/manage_beautyarticle/InputBeautyArticle';
import DetailBeautyArticle from 'views/omg_pages/manage_beautyarticle/DetailBeautyArticle';
import EditBeautyArticle from 'views/omg_pages/manage_beautyarticle/EditBeautyArticle';

import ProductReviewList from 'views/omg_pages/manage_productreview/ProductReviewList';
import SubscriberList from 'views/omg_pages/manage_subscriber/SubscriberList';

import ProductList from 'views/omg_pages/manage_product/ProductList';
import InputProduct from 'views/omg_pages/manage_product/InputProduct';
import DetailProduct from 'views/omg_pages/manage_product/DetailProduct';
import EditProduct from 'views/omg_pages/manage_product/EditProduct';

import SkincareProductList from 'views/omg_pages/manage_skincare/SkincareProductList';
import InputProductSkincare from 'views/omg_pages/manage_skincare/InputProductSkincare';

import QuestionList from 'views/omg_pages/manage_beautyquiz/QuestionList';
import InputQuestion from 'views/omg_pages/manage_beautyquiz/InputQuestion';
import ResponseList from 'views/omg_pages/manage_beautyquiz/ResponseList';
import InputResponse from 'views/omg_pages/manage_beautyquiz/InputResponse';
import DetailQuestion from 'views/omg_pages/manage_beautyquiz/DetailQuestion';
import EditQuestion from 'views/omg_pages/manage_beautyquiz/EditQuestion';
import DetailResponse from 'views/omg_pages/manage_beautyquiz/DetailResponse';
import EditResponse from 'views/omg_pages/manage_beautyquiz/EditResponse';
import InputSkincareBanner from 'views/omg_pages/manage_skincarebanner/InputSkincareBanner';
import SkincareBannerList from 'views/omg_pages/manage_skincarebanner/SkincareBannerList';
import DetailSkincareBanner from 'views/omg_pages/manage_skincarebanner/DetailSkincareBanner';
import EditSkincareBanner from 'views/omg_pages/manage_skincarebanner/EditSkincareBanner';
import DetailProductSkincare from 'views/omg_pages/manage_skincare/DetailProductSkincare';
import EditSkincareProduct from 'views/omg_pages/manage_skincare/EditSkincareProduct';
import InputMakeUpBanner from 'views/omg_pages/manage_makeupbanner/InputMakeUpBanner';
import MakeUpBannerList from 'views/omg_pages/manage_makeupbanner/MakeUpBannerList';
import DetailMakeUpBanner from 'views/omg_pages/manage_makeupbanner/DetailMakeUpBanner';
import EditMakeUpBanner from 'views/omg_pages/manage_makeupbanner/EditMakeUpBanner';
import ProductReviewInput from 'views/omg_pages/manage_productreview/ProductReviewInput';
import PollQuestionList from 'views/omg_pages/manage_surveypoll/PollQuestionList';
import InputPollQuestion from 'views/omg_pages/manage_surveypoll/InputPollQuestion';
import EditPollQuestion from 'views/omg_pages/manage_surveypoll/EditPollQuestion';
import DetailPollQuestion from 'views/omg_pages/manage_surveypoll/DetailPollQuestion';
import PollResponseList from 'views/omg_pages/manage_surveypoll/PollResponseList';

import InputSkincareQuestion from 'views/omg_pages/manage_skincarequiz/InputQuestion';
import QuestionSkincareList from 'views/omg_pages/manage_skincarequiz/QuestionList';
import EditSkincareQuestion from 'views/omg_pages/manage_skincarequiz/EditQuestion';
import DetailSkincareQuestion from 'views/omg_pages/manage_skincarequiz/DetailQuestion';
import ResponseSkincareList from 'views/omg_pages/manage_skincarequiz/ResponseList';
import InputSkincareResponse from 'views/omg_pages/manage_skincarequiz/InputResponse';
import DetailSkincareResponse from 'views/omg_pages/manage_skincarequiz/DetailResponse';
import EditSkincareResponse from 'views/omg_pages/manage_skincarequiz/EditResponse';

const routes = [
  {
    collapse: false,
    path: '/dashboard',
    name: 'Home',
    icon: 'fa fa-home text-pink',
    component: Homepage,
    layout: '/admin',
  },
  {
    collapse: true,
    name: 'Home Banner',
    icon: 'fa fa-file-image text-pink',
    state: 'requestCollapse',
    views: [
      {
        path: '/input-home-banner',
        name: 'Input Home Banner',
        miniName: 'IB',
        component: InputHomeBanner,
        layout: '/admin',
      },
      {
        path: '/list-home-banner',
        name: 'Home Banner List',
        miniName: 'BL',
        component: HomeBannerList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Skincare Banner',
    icon: 'fa fa-file-image text-pink',
    state: 'skincareBannerCollapse',
    views: [
      {
        path: '/input-skincare-banner',
        name: 'Input Skincare Banner',
        miniName: 'ISB',
        component: InputSkincareBanner,
        layout: '/admin',
      },
      {
        path: '/list-skincare-banner',
        name: 'Skincare Banner List',
        miniName: 'SBL',
        component: SkincareBannerList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Make Up Banner',
    icon: 'fa fa-file-image text-pink',
    state: 'bannerMakeUpCollapse',
    views: [
      {
        path: '/input-make-up-banner',
        name: 'Input Make Up Banner',
        miniName: 'IMB',
        component: InputMakeUpBanner,
        layout: '/admin',
      },
      {
        path: '/list-make-up-banner',
        name: 'Make Up Banner List',
        miniName: 'MBL',
        component: MakeUpBannerList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'OMG of the Month',
    icon: 'fa fa-clipboard text-pink',
    state: 'requestCollapse1',
    views: [
      {
        path: '/input-omg-of-the-month',
        name: 'Input OMG of the Month',
        miniName: 'ICS',
        component: InputContentSubmission,
        layout: '/admin',
      },
      {
        path: '/omg-of-the-month-list',
        name: 'OMG of the Month List',
        miniName: 'CSL',
        component: ContentSubmissionList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Beauty Tutorial',
    icon: 'fa fa-magic text-pink',
    state: 'requestCollapse2',
    views: [
      {
        path: '/input-beauty-tutorial',
        name: 'Input Beauty Tutorial',
        miniName: 'IBT',
        component: InputBeautyTutorial,
        layout: '/admin',
      },
      {
        path: '/beauty-tutorial-list',
        name: 'Beauty Tutorial List',
        miniName: 'BTL',
        component: BeautyTutorialList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Product Review',
    icon: 'fa fa-star text-pink',
    state: 'requestCollapse3',
    views: [
      {
        path: '/product-review-input',
        name: 'Input Product Review',
        miniName: 'PRI',
        component: ProductReviewInput,
        layout: '/admin',
      },
      {
        path: '/product-review-list',
        name: 'Product Review List',
        miniName: 'PRL',
        component: ProductReviewList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'KOL Review',
    icon: 'fa fa-comment text-pink',
    state: 'requestCollapse4',
    views: [
      {
        path: '/input-kol-review',
        name: 'Input KOL Review',
        miniName: 'IKR',
        component: InputKOLReview,
        layout: '/admin',
      },
      {
        path: '/kol-review-list',
        name: 'KOL Review List',
        miniName: 'KRL',
        component: KOLReviewList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Subscribers',
    icon: 'fa fa-users text-pink',
    state: 'requestCollapse5',
    views: [
      {
        path: '/subscriber-list',
        name: 'Subscriber List',
        miniName: 'SUB',
        component: SubscriberList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Beauty Tips',
    icon: 'fa fa-bullhorn text-pink',
    state: 'articleCollapse',
    views: [
      {
        path: '/input-beauty-tips',
        name: 'Input Beauty Tips',
        miniName: 'IBT',
        component: InputBeautyArticle,
        layout: '/admin',
      },
      {
        path: '/list-beauty-tips',
        name: 'List Beauty Tips',
        miniName: 'LBT',
        component: BeautyArticleList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Product Make Up',
    icon: 'fa fa-palette text-pink',
    state: 'makeUpCollapse',
    views: [
      {
        path: '/input-make-up',
        name: 'Input Make Up',
        miniName: 'IMU',
        component: InputProduct,
        layout: '/admin',
      },
      {
        path: '/list-make-up',
        name: 'List Make Up',
        miniName: 'LMU',
        component: ProductList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Product Skincare',
    icon: 'fa fa-shopping-bag text-pink',
    state: 'skincareCollapse',
    views: [
      {
        path: '/input-product-skincare',
        name: 'Input Product Skincare',
        miniName: 'IPS',
        component: InputProductSkincare,
        layout: '/admin',
      },
      {
        path: '/list-product-skincare',
        name: 'List Product Skincare',
        miniName: 'LPS',
        component: SkincareProductList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Make Up Quiz',
    icon: 'fa fa fa-question-circle text-pink',
    state: 'quizCollapse',
    views: [
      {
        path: '/input-question',
        name: 'Input Question',
        miniName: 'IBQ',
        component: InputQuestion,
        layout: '/admin',
      },
      {
        path: '/list-question',
        name: 'List Question',
        miniName: 'LBQ',
        component: QuestionList,
        layout: '/admin',
      },
      {
        path: '/input-response',
        name: 'Input Response',
        miniName: 'IQR',
        component: InputResponse,
        layout: '/admin',
      },
      {
        path: '/list-response',
        name: 'List Response',
        miniName: 'LQR',
        component: ResponseList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Skin Care Quiz',
    icon: 'fa fa fa-question-circle text-pink',
    state: 'skincareQuizCollapse',
    views: [
      {
        path: '/input-skincare-question',
        name: 'Input Question',
        miniName: 'ISQ',
        component: InputSkincareQuestion,
        layout: '/admin',
      },
      {
        path: '/list-skincare-question',
        name: 'List Question',
        miniName: 'LSQ',
        component: QuestionSkincareList,
        layout: '/admin',
      },
      {
        path: '/input-skincare-response',
        name: 'Input Response',
        miniName: 'ISR',
        component: InputSkincareResponse,
        layout: '/admin',
      },
      {
        path: '/list-skincare-response',
        name: 'List Response',
        miniName: 'LSR',
        component: ResponseSkincareList,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    name: 'Survey & Poll',
    icon: 'fa fa fa-question-circle text-pink',
    state: 'surveyCollapse',
    views: [
      {
        path: '/input-poll-question',
        name: 'Input Poll Question',
        miniName: 'IPQ',
        component: InputPollQuestion,
        layout: '/admin',
      },
      {
        path: '/list-poll-questions',
        name: 'List Poll Questions',
        miniName: 'PQL',
        component: PollQuestionList,
        layout: '/admin',
      },
      {
        path: '/list-poll-responses',
        name: 'Poll Responses',
        miniName: 'LPR',
        component: PollResponseList,
        layout: '/admin',
      },
    ],
  },
  {
    redirect: true,
    collapse: false,
    path: '/login',
    component: Login,
    layout: '/auth',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-home-banner/:id',
    component: DetailHomeBanner,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-home-banner/:id',
    component: EditHomeBanner,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-skincare-banner/:id',
    component: DetailSkincareBanner,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-make-up-banner/:id',
    component: DetailMakeUpBanner,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-skincare-banner/:id',
    component: EditSkincareBanner,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-make-up-banner/:id',
    component: EditMakeUpBanner,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-beauty-tutorial/:id',
    component: DetailBeautyTutorial,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-beauty-tutorial/:id',
    component: EditBeautyTutorial,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-beauty-tips/:id',
    component: DetailBeautyArticle,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-beauty-tips/:id',
    component: EditBeautyArticle,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-omg-of-the-month/:id',
    component: DetailContentSubmission,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-omg-of-the-month/:id',
    component: EditContentSubmission,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-kol-review/:id',
    component: DetailKOLReview,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-kol-review/:id',
    component: EditKOLReview,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-make-up/:id',
    component: DetailProduct,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-product-skincare/:id',
    component: DetailProductSkincare,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-make-up/:id',
    component: EditProduct,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-product-skincare/:id',
    component: EditSkincareProduct,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-question/:id',
    component: DetailQuestion,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-question/:id',
    component: EditQuestion,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-response/:id',
    component: DetailResponse,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-response/:id',
    component: EditResponse,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-skincare-question/:id',
    component: DetailSkincareQuestion,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-skincare-question/:id',
    component: EditSkincareQuestion,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-skincare-response/:id',
    component: DetailSkincareResponse,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-skincare-response/:id',
    component: EditSkincareResponse,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/edit-poll-question/:id',
    component: EditPollQuestion,
    layout: '/admin',
  },
  {
    redirect: true,
    collapse: false,
    path: '/detail-poll-question/:id',
    component: DetailPollQuestion,
    layout: '/admin',
  },
];

export default routes;
