/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import Logo from 'assets/img/brand/OMG.png';
import People from 'assets/img/brand/model1.png';
// import Cookies from 'js-cookie';
// import { qoreContext } from '../../../context';

function Homepage() {
  //   const { user } = qoreContext.useCurrentUser();

  //   useEffect(() => {
  //     if (user) {
  //       console.log(user);
  //       Cookies.set('pmiID', user.data.pmiFk.id);
  //       Cookies.set('pmiName', user.data.pmiFk.displayField);
  //     }
  //   }, [user]);
  return (
    <>
      <AlternativeHeader parentName='Homepage' />
      <Container className='mt--6' fluid>
        <Row>
          <div className='col'>
            <div className='card-wrapper'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Homepage</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg='6'>
                      <div
                        className='text-center'
                        style={{ margin: '100px auto' }}
                      >
                        <img
                          alt='logo'
                          src={Logo}
                          style={{ maxHeight: '300px', width: 'auto' }}
                        />
                        <div style={{ margin: '0 auto' }}>
                          <h1>Welcome to OMG CMS</h1>
                        </div>
                      </div>
                    </Col>
                    <Col lg='6'>
                      <img
                        alt='people'
                        src={People}
                        style={{
                          height: '60vh',
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Homepage;
