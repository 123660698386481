import React, { useEffect, useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

const EditResponse = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const { data, status } = qoreContext.view('allProducts').useGetRow(id);
  const { data: products } = qoreContext.view('allProducts').useListRow({});
  const { updateRow } = qoreContext.view('allProducts').useUpdateRow();

  //state
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [formData, setFormData] = useState(null);
  const [productChoice, setProductChoice] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const convertHtmlToDraft = (articleHTML) => {
    let contentBlock = htmlToDraft(articleHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const article = EditorState.createWithContent(contentState);
      setEditorState(article);
    }
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFormData({ ...formData, quizDesc: currentContentAsHTML });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSelect = (e) => {
    const selected = productChoice.filter(
      (product) => product.id === e.target.value
    );

    setSelectedProduct(selected[0]);
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (selectedProduct.id !== id) {
        console.log(selectedProduct.id, 'product Beda');
        const deleted = await updateRow(id, { quizTitle: '', quizDesc: '' });
        if (deleted) {
          updateRow(selectedProduct.id, {
            quizTitle: formData.quizTitle,
            quizDesc: formData.quizDesc,
          });
        }
      } else {
        updateRow(selectedProduct.id, {
          quizTitle: formData.quizTitle,
          quizDesc: formData.quizDesc,
        });
      }

      Swal.fire({
        icon: 'success',
        text: 'Quiz response has been updated.',
      });
      history.push('/admin/list-response');
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        text: 'Fail to update quiz response',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      convertHtmlToDraft(data.quizDesc);
      setSelectedProduct(data);
      setFormData(data);
    }
  }, [data]);

  useEffect(() => {
    if (products) {
      const filtered = products.filter(
        (product) => !product.quizTitle && !product.quizDesc
      );

      const productNow = products.filter((product) => product.id === id);

      const choiceArray = [...filtered, ...productNow];

      setProductChoice(choiceArray);
    }
  }, [products, id]);

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Quiz'
        name='Edit Quiz Response'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Edit Quiz Response</h3>
              </CardHeader>
              <CardBody>
                {status !== 'success' || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Product</label>
                      <Input
                        type='select'
                        id='product'
                        name='product'
                        onChange={handleSelect}
                        value={selectedProduct?.id}
                      >
                        {productChoice?.map((product, idx) => (
                          <option
                            value={product.id}
                            key={idx}
                          >{`${product.no} ${product.name}`}</option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Popup Image</label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={selectedProduct?.imgSwatch}
                          alt='Popup product'
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Model Image</label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={selectedProduct?.imgModel}
                          alt='Model product'
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Title</label>
                      <Input
                        type='text'
                        name='quizTitle'
                        placeholder='Title of Article'
                        value={formData?.quizTitle}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Editor
                        editorState={editorState}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        onEditorStateChange={handleEditorChange}
                        // readOnly={true}
                      />
                    </FormGroup>
                    <div className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={!isEdited ? true : false}
                      >
                        Submit
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.push('/admin/list-response');
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditResponse;
