import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const EditQuestion = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const { data, status, revalidate } = qoreContext
    .view('allQuizSkincare')
    .useGetRow(id);
  const { updateRow } = qoreContext.view('allQuizSkincare').useUpdateRow();

  // state
  const [formData, setFormData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    if (data && status === 'success') {
      setFormData({
        question: data.question,
        choices: data.choices,
      });
    }
  }, [data, status]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateRow(id, {
      question: formData.question,
      choices: formData.choices,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Question has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to update question',
        });
      });
  };

  return (
    <>
      <AlternativeHeader parentName='Manage Beauty Quiz' name='Edit Question' />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Edit Skincare Question</h3>
              </CardHeader>
              <CardBody>
                {!formData || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Question</label>
                      <Input
                        type='text'
                        name='question'
                        placeholder='Insert question'
                        value={formData?.question}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className='form-control-label'>Choices</label>
                          <Editor
                            value={formData?.choices}
                            onChange={(e) => {
                              setFormData({ ...formData, choices: e });
                              if (!isEdited) {
                                setIsEdited(true);
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={!isEdited ? true : false}
                      >
                        Submit
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.push('/admin/list-skincare-question');
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditQuestion;
