import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import Swal from 'sweetalert2';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Input.css';

const EditBeautyArticle = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const client = qoreContext.useClient();
  const { data, status, revalidate } = qoreContext
    .view('allBeautyTips')
    .useGetRow(id);
  const { updateRow } = qoreContext.view('allBeautyTips').useUpdateRow();

  // state
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isImgChange, setIsImgChange] = useState(false);
  const [isWebpChange, setIsWebpChange] = useState(false);
  const [uploadDone, setUploadDone] = useState(true);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const convertHtmlToDraft = (articleHTML) => {
    let contentBlock = htmlToDraft(articleHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const article = EditorState.createWithContent(contentState);
      setEditorState(article);
    }
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFormData({ ...formData, details: currentContentAsHTML });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleImage = async (picture) => {
    const imageArticle = picture[0];
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allBeautyTips')
      .upload(imageArticle)
      .then((responseUpload) => {
        setFormData({ ...formData, img: responseUpload });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  const handleWebp = async (picture) => {
    const imageArticle = picture[0];
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allBeautyTips')
      .upload(imageArticle)
      .then((responseUpload) => {
        setFormData({ ...formData, imgWebp: responseUpload });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      client
        .view('allBeautyTips')
        .upload(file)
        .then((url) => {
          resolve({ data: { link: url } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const convertToSlug = (title) => {
    const slug = title
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
    return slug;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateRow(id, {
      title: formData?.title,
      img: formData?.img,
      imgWebp: formData?.imgWebp,
      details: formData?.details,
      slug: convertToSlug(formData?.title),
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Article has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to update article',
        });
      });
  };

  useEffect(() => {
    if (data && status === 'success') {
      setFormData(data);
      convertHtmlToDraft(data.details);
    }
  }, [data, status]);

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Article'
        name='Edit Beauty Article'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='12'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Edit Beauty Article</h3>
              </CardHeader>
              <CardBody>
                {status !== 'success' || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Title</label>
                      <Input
                        type='text'
                        name='title'
                        placeholder='Title of Article'
                        value={formData?.title}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Article Image
                      </label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={formData?.img}
                          alt='article'
                        />
                      </div>
                      {isImgChange ? (
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={handleImage}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      ) : (
                        <Button
                          color='primary'
                          style={{ marginTop: '8px' }}
                          onClick={() => {
                            setIsImgChange(true);
                          }}
                        >
                          Change Article Image
                        </Button>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Article Image (webp Format)
                      </label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={
                            formData?.imgWebp ||
                            'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png'
                          }
                          alt='article'
                        />
                      </div>
                      {isWebpChange ? (
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={handleWebp}
                          label='Max : 2 MB, accepted: webp'
                          imgExtension={['.webp']}
                          maxFileSize={2097152}
                        />
                      ) : (
                        <Button
                          color='primary'
                          style={{ marginTop: '8px' }}
                          onClick={() => {
                            setIsWebpChange(true);
                          }}
                        >
                          Change Article Image
                        </Button>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Details</label>
                      <Editor
                        editorState={editorState}
                        editorStyle={{ fontFamily: 'Avenir' }}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        onEditorStateChange={handleEditorChange}
                        toolbar={{
                          options: [
                            'inline',
                            'list',
                            'textAlign',
                            'fontFamily',
                            'fontSize',
                            'link',
                            'image',
                            'history',
                          ],
                          fontFamily: { options: ['Avenir'] },
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: uploadImageCallBack,
                            alt: { present: true, mandatory: true },
                          },
                        }}
                      />
                    </FormGroup>
                    <div className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={!isEdited || !uploadDone ? true : false}
                      >
                        Submit
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditBeautyArticle;
