import React, { useEffect, useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const DetailKOLReview = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const { data, status } = qoreContext.view('allKolReview').useGetRow(id);
  const { deleteRow } = qoreContext.view('allKolReview').useDeleteRow();

  //state
  // const [data_template, setDataTemplate] = useState(null);
  const [data_active, setDataActive] = useState(null);

  useEffect(() => {
    // console.log(data);
    // if (data?.template) {
    //   setDataTemplate('YES');
    // } else {
    //   setDataTemplate('NO');
    // }
    if (data?.active) {
      setDataActive('ACTIVE');
    } else {
      setDataActive('NOT ACTIVE');
    }
  }, [data]);

  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you sure to DELETE this KOL Review?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRow(id)
          .then(() => {
            Swal.fire({
              icon: 'success',
              text: 'KOL Review has been deleted.',
            });
            history.goBack();
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: 'Fail to update request status (Server Error)',
            });
            console.error(error);
          });
      }
    });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage KOL Review'
        name='Details KOL Review'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Details KOL Review</h3>
              </CardHeader>
              <CardBody>
                {status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form>
                    <FormGroup>
                      <label className='form-control-label'>Index</label>
                      <Input
                        type='number'
                        name='index'
                        placeholder='Index'
                        value={data.index}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Name</label>
                      <Input
                        type='text'
                        name='name'
                        placeholder='Name of KOL Review'
                        value={data.name}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Input
                        type='text'
                        name='description'
                        placeholder='Description of KOL Review'
                        value={data.description}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Image Content
                      </label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={data.img}
                          alt='content'
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Image Content (.webp Format)
                      </label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={
                            data?.imgWebp ||
                            'https://westsiderc.org/wp-content/uploads/2019/08/Image-Not-Available.png'
                          }
                          alt='content'
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Sign</label>
                      <Input
                        type='text'
                        name='sign'
                        placeholder='Sign of KOL Review'
                        value={data.sign}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='exampleFormControlSelect3'
                      >
                        KOL Active
                      </label>
                      <Input
                        id='exampleFormControlSelect3'
                        type='text'
                        name='active'
                        value={data_active}
                        readOnly
                      ></Input>
                    </FormGroup>
                    <div className='text-right'>
                      <Button
                        type='button'
                        color='primary'
                        onClick={() => {
                          history.push(`/admin/kol-review-list`);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        type='button'
                        color='dark'
                        onClick={() => {
                          history.push(`/admin/edit-kol-review/${id}`);
                        }}
                        outline
                      >
                        Edit
                      </Button>
                      <Button
                        type='button'
                        color='danger'
                        onClick={handleDelete}
                        outline
                      >
                        Delete
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailKOLReview;
