import React, { useEffect, useState } from 'react';

// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Spinner,
  Button,
  CardBody,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { CSVLink } from 'react-csv';
import moment from 'moment';

// pagination
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Show{' '}
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{' '}
        entries.
      </label>
    </div>
  ),
});

// search bar
const { SearchBar } = Search;

const BeautyTutorialList = () => {
  // data productReview from qore
  const { data: subsciber, status } = qoreContext
    .view('allSubscriber')
    .useListRow({});

  //state
  const [subscriberFiltered, setSubscriber] = useState([]);

  useEffect(() => {
    setSubscriber(subsciber);
  }, [subsciber]);

  return (
    <>
      <AlternativeHeader
        parentName='Manage Subscriber'
        name='Subscriber List'
      />
      <Container className='mt--6' fluid>
        <Row>
          <div className='col'>
            <div className='card-wrapper'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Subscriber List</h3>
                </CardHeader>
                {status === 'success' ? (
                  <ToolkitProvider
                    data={subscriberFiltered}
                    keyField='subscriber'
                    columns={[
                      {
                        dataField: 'name',
                        text: 'name',
                        sort: true,
                        style: {
                          whiteSpace: 'unset',
                        },
                      },
                      {
                        dataField: 'email',
                        text: 'email',
                        sort: true,
                        style: {
                          whiteSpace: 'unset',
                        },
                      },
                      {
                        dataField: 'createdAt',
                        text: 'Create Date',
                        sort: true,
                        formatter: (cell, row) => {
                          return (
                            <>
                              {moment(row.createdAt).format(
                                'DD MMM YYYY hh:mm:ss'
                              )}
                            </>
                          );
                        },
                      },
                      {
                        dataField: 'updatedAt',
                        text: 'Updated Date',
                        sort: true,
                        formatter: (cell, row) => {
                          return (
                            <>
                              {moment(row.updatedAt).format(
                                'DD MMM YYYY hh:mm:ss'
                              )}
                            </>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className='py-4 table-responsive'>
                        <Row>
                          <Col md='6' xl='3'>
                            <div
                              id='datatable-basic_filter'
                              className='dataTables_filter px-4 pb-1'
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className='form-control-sm'
                                  placeholder=''
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col md='2' xl='2'>
                            {subscriberFiltered.length > 0 ? (
                              <Button color='primary' size='sm' type='button'>
                                <CSVLink
                                  data={subscriberFiltered}
                                  filename='List_Subscriber_OMG.csv'
                                  style={{ color: '#FFFFFF' }}
                                >
                                  Download
                                </CSVLink>
                              </Button>
                            ) : null}
                          </Col>
                        </Row>

                        <BootstrapTable
                          {...props.baseProps}
                          keyField='id'
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <CardBody>
                    <div className='text-center' style={{ minHeight: '50vh' }}>
                      <Spinner />
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BeautyTutorialList;
