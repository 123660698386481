import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const formQuestion = {
  question:
    'Dimulai saat pagi hari, Apa yang kamu rasakan saat menyentuh wajahmu?',
  choices: [
    {
      answer:
        'Di area T-zone berminyak dan terdapat komedo, sedangkan bagian pipi tidak berminyak.',
      points: {
        name: 'combination',
        value: 1,
      },
    },
    {
      answer:
        'Seluruh wajah berminyak, terkadang kulit terasa kasar karena komedo atau jerawat.',
      points: {
        name: 'oily',
        value: 2,
      },
    },
    {
      answer: 'Kulit terasa lembut dan cukup lembap.',
      points: {
        name: 'dry',
        value: 3,
      },
    },
    {
      answer:
        'Kulit terasa kering, terkadang ada bagian yang sangat kering dan mengelupas.',
      points: {
        name: 'normal',
        value: 5,
      },
    },
  ],
};

const InputQuestion = () => {
  // state
  const [formData, setFormData] = useState(formQuestion);
  const [loading, setLoading] = useState(false);

  // qore
  const { insertRow } = qoreContext.view('allQuizSkincare').useInsertRow();

  const { data: allQuestions, status } = qoreContext
    .view('allQuizSkincare')
    .useListRow({});

  // history
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newQuestion = await insertRow(formData);

      if (newQuestion) {
        Swal.fire({
          icon: 'success',
          text: 'Question has been submited',
        });
        setFormData(formQuestion);
        setLoading(false);
        history.push('/admin/list-skincare-question');
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Fail to submit question',
      });
    }
  };

  useEffect(() => {
    if (allQuestions.length > 0) {
      setFormData((formData) => ({
        ...formData,
        index: allQuestions.length + 1,
      }));
    }
  }, [allQuestions]);

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Quiz'
        name='Input Question'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Question</h3>
              </CardHeader>
              <CardBody>
                {loading || status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Index</label>
                      <Input
                        type='number'
                        name='index'
                        placeholder='Index of Home Banner'
                        value={formData.index}
                        disabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Question</label>
                      <Input
                        type='text'
                        name='question'
                        placeholder='Insert question'
                        value={formData.question}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            question: e.target.value,
                          });
                        }}
                        required
                      />
                    </FormGroup>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <label className='form-control-label'>Choices</label>
                          <Editor
                            value={formData.choices}
                            onChange={(e) => {
                              setFormData({ ...formData, choices: e });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputQuestion;
