import React from 'react';

// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Button,
  CardBody,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

// pagination
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Show{' '}
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{' '}
        entries.
      </label>
    </div>
  ),
});

// search bar
const { SearchBar } = Search;

const QuestionList = () => {
  // data banners from qore
  const {
    data: quizzes,
    status,
    revalidate,
  } = qoreContext.view('allQuiz').useListRow({});

  // update row banner to qore
  const { updateRow: updateStatus } = qoreContext
    .view('allQuiz')
    .useUpdateRow();

  const BannerStatus = (status, id) => {
    if (status) {
      return (
        <Button
          color='success'
          onClick={() => {
            handleUpdateStatus(status, id);
          }}
          title='click to hide'
        >
          <span>Published</span>
        </Button>
      );
    } else {
      return (
        <Button
          color='danger'
          onClick={() => {
            handleUpdateStatus(status, id);
          }}
          title='click to publish'
        >
          <span>Hidden</span>
        </Button>
      );
    }
  };

  const handleUpdateStatus = (status, idBanner) => {
    Swal.fire({
      title: `Are you sure to ${status ? 'hide' : 'publish'} this question?`,
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus(idBanner, { active: !status })
          .then((responseUpdate) => {
            revalidate();

            Swal.fire({
              icon: 'success',
              text: 'Question status has been updated.',
            });
          })
          .catch((error) => {
            console.log(error.response);

            Swal.fire({
              icon: 'error',
              text: 'Fail to update question status (Server Error)',
            });
          });
      }
    });
  };

  return (
    <>
      <AlternativeHeader parentName='Manage Beauty Quiz' name='Question List' />
      <Container className='mt--6' fluid>
        <Row>
          <div className='col'>
            <div className='card-wrapper'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Question List</h3>
                </CardHeader>
                {status === 'success' ? (
                  <ToolkitProvider
                    data={quizzes}
                    keyField='quizzes'
                    columns={[
                      {
                        dataField: 'question',
                        text: 'Question',
                        sort: true,
                      },
                      {
                        editable: false,
                        dataField: 'imgOne',
                        text: 'Choice 1',
                        formatter: (cell, row) => {
                          return (
                            <div
                              style={{ textAlign: 'center', maxWidth: '100px' }}
                            >
                              <img
                                src={row.imgOne}
                                style={{ maxHeight: '80px' }}
                                alt='one'
                              />
                              <div style={{ marginTop: '2px' }}>
                                <span style={{ whiteSpace: 'initial' }}>
                                  {row.choice1}
                                </span>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        editable: false,
                        dataField: 'imgTwo',
                        text: 'Choice 2',
                        formatter: (cell, row) => {
                          return (
                            <div
                              style={{ textAlign: 'center', maxWidth: '100px' }}
                            >
                              <img
                                src={row.imgTwo}
                                style={{ maxHeight: '80px' }}
                                alt='two'
                              />
                              <div style={{ marginTop: '2px' }}>
                                <span style={{ whiteSpace: 'initial' }}>
                                  {row.choice2}
                                </span>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        editable: false,
                        dataField: 'imgThree',
                        text: 'Choice 3',
                        formatter: (cell, row) => {
                          return (
                            <div
                              style={{ textAlign: 'center', maxWidth: '100px' }}
                            >
                              <img
                                src={row.imgThree}
                                style={{ maxHeight: '80px' }}
                                alt='three'
                              />
                              <div style={{ marginTop: '2px' }}>
                                <span style={{ whiteSpace: 'initial' }}>
                                  {row.choice3}
                                </span>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        editable: false,
                        dataField: 'imgFour',
                        text: 'Choice 4',
                        formatter: (cell, row) => {
                          return (
                            <div
                              style={{ textAlign: 'center', maxWidth: '100px' }}
                            >
                              <img
                                src={row.imgFour}
                                style={{ maxHeight: '80px' }}
                                alt='four'
                              />
                              <div style={{ marginTop: '2px' }}>
                                <span style={{ whiteSpace: 'initial' }}>
                                  {row.choice4}
                                </span>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        editable: false,
                        dataField: 'imgFive',
                        text: 'Choice 5',
                        formatter: (cell, row) => {
                          return (
                            <div
                              style={{ textAlign: 'center', maxWidth: '100px' }}
                            >
                              <img
                                src={row.imgFive}
                                style={{ maxHeight: '80px' }}
                                alt='five'
                              />
                              <div style={{ marginTop: '2px' }}>
                                <span style={{ whiteSpace: 'initial' }}>
                                  {row.choice5}
                                </span>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        dataField: 'active',
                        editable: false,
                        text: 'Status',
                        formatter: (cell, row) => {
                          return <>{BannerStatus(row.active, row.id)}</>;
                        },
                      },
                      {
                        dataField: 'id',
                        editable: false,
                        text: 'Action',
                        formatter: (cell, row) => {
                          return (
                            <>
                              <Link
                                className='btn btn-info'
                                title='More info'
                                to={`/admin/detail-question/${row.id}`}
                              >
                                <i className='fa fa-info-circle' />
                              </Link>
                              <Link
                                className='btn btn-secondary'
                                title='Edit'
                                to={`/admin/edit-question/${row.id}`}
                              >
                                <i className='fas fa-user-edit' />
                              </Link>
                            </>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className='py-4 table-responsive'>
                        <div
                          id='datatable-basic_filter'
                          className='dataTables_filter px-4 pb-1'
                        >
                          <label>
                            Search:
                            <SearchBar
                              className='form-control-sm'
                              placeholder=''
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField='id'
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <CardBody>
                    <div className='text-center' style={{ minHeight: '50vh' }}>
                      <Spinner />
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default QuestionList;
