import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const DetailProduct = () => {
  // state
  const [tipsState, setTipsState] = useState(() => EditorState.createEmpty());

  const [infoState, setInfoState] = useState(() => EditorState.createEmpty());

  const [ingredientState, setIngredientState] = useState(() =>
    EditorState.createEmpty()
  );

  const [faqState, setFaqState] = useState(() => EditorState.createEmpty());
  
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const { data, status } = qoreContext.view('allProducts').useGetRow(id);
  const { deleteRow } = qoreContext.view('allProducts').useDeleteRow();

  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you sure to DELETE this product?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRow(id)
          .then(() => {
            Swal.fire({
              icon: 'success',
              text: 'Product has been deleted.',
            });
            history.goBack();
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: 'Fail to delete product (Server Error)',
            });
            console.error(error);
          });
      }
    });
  };

  useEffect(() => {
    if (data && status === 'success') {
      convertHtmlToDraft(data.tipsPenggunaan, 'tipsPengunaan');
      convertHtmlToDraft(data.productInformation, 'productInformation');
      convertHtmlToDraft(data.ingredients, 'ingredients');
      convertHtmlToDraft(data.faq, 'faq');
    }
  }, [data, status]);

  const convertHtmlToDraft = (articleHTML, text) => {
    if (articleHTML) {
      let contentBlock = htmlToDraft(articleHTML);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const article = EditorState.createWithContent(contentState);

        if (text === 'tipsPengunaan') {
          setTipsState(article);
        } else if (text === 'productInformation') {
          setInfoState(article);
        } else if (text === 'ingredients') {
          setIngredientState(article);
        } else if (text === 'faq') {
          setFaqState(article);
        }
      }
    }
  };

  return (
    <>
      <AlternativeHeader parentName='Manage Make Up' name='Detail Make Up' />
      <Container className='mt--6' fluid>
        {status !== 'success' ? (
          <div className='text-center'>
            <Spinner />
            <p>loading</p>
          </div>
        ) : (
          <Form>
          <Row>
            <Col lg='6'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Detail Make Up</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <label className='form-control-label'>No</label>
                        <Input
                          type='text'
                          name='no'
                          placeholder='Insert product number'
                          value={data.no}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md={9}>
                      <FormGroup>
                        <label className='form-control-label'>Product Shade</label>
                        <Input
                          type='text'
                          name='name'
                          placeholder='Insert product name'
                          value={data.name}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label className='form-control-label'>
                      Product Name
                    </label>
                    <Input
                      type='text'
                      name='parentName'
                      placeholder='Insert Product Name'
                      value={data.parentName}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Hexcolor (ex: #cf578a)
                    </label>
                    <Input
                      type='text'
                      name='hexColor'
                      placeholder='Insert hexcolor code'
                      value={data.hexColor}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>BPOM</label>
                    <Input
                      type='text'
                      name='bpom'
                      placeholder='Insert BPOM number'
                      value={data.bpom}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Price</label>
                    <Input
                      type='number'
                      name='price'
                      placeholder='Insert product price'
                      value={data.price}
                      readOnly
                    />
                  </FormGroup>
                  <FormGroup>
                  <label className='form-control-label'>Product Description</label>
                    <Input
                      type='textarea'
                      rows='5'
                      name='productDescription'
                      placeholder='Insert product description'
                      value={data?.productDescription}
                      readOnly
                    />
                  </FormGroup>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className='form-control-label'>Shopee</label>
                        <Input
                          type='text'
                          name='shopee'
                          placeholder='insert product link on shopee'
                          value={data?.shopee}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className='form-control-label'>
                          Tokopedia
                        </label>
                        <Input
                          type='text'
                          name='tokopedia'
                          placeholder='insert product link on tokopedia'
                          value={data?.tokopedia}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className='form-control-label'>Lazada</label>
                        <Input
                          type='text'
                          name='lazada'
                          placeholder='insert product link on lazada'
                          value={data?.lazada}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className='form-control-label'>Blibli</label>
                        <Input
                          type='text'
                          name='blibli'
                          placeholder='insert product link on blibli'
                          value={data?.blibli}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label className='form-control-label'>
                      Product Image
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={data.imgProduct}
                        alt='product'
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Bottle Image</label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={data.imgBottle}
                        alt='bottle'
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Applicator Image
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={data.imgApplicator}
                        alt='Applicator'
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Popup Image</label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={data.imgSwatch}
                        alt='Popup product'
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Product On Lip
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={data.imgLip}
                        alt='product on lip'
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Swatch On Hand
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={data.imgHand}
                        alt='product on hand'
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Product On Model
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={data.imgModel}
                        alt='product on model'
                      />
                    </div>
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className='form-control-label'>
                        Claim Image 1
                        </label>
                        <div>
                          <img
                            style={{ maxHeight: '360px' }}
                            src={data.claimImage1}
                            alt='claim 1'
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label className='form-control-label'>Claim Text 1</label>
                        <Input
                          type='text'
                          name='claimText1'
                          placeholder='claim text 1'
                          value={data?.claimText1}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className='form-control-label'>
                          Claim Image 2
                        </label>
                        <div>
                          <img
                            style={{ maxHeight: '360px' }}
                            src={data.claimImage2}
                            alt='claim 2'
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label className='form-control-label'>Claim Text 2</label>
                        <Input
                          type='text'
                          name='claimText2'
                          placeholder='claim text 2'
                          value={data?.claimText2}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className='form-control-label'>
                          Claim Image 3
                        </label>
                        <div>
                          <img
                            style={{ maxHeight: '360px' }}
                            src={data.claimImage3}
                            alt='claim 3'
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label className='form-control-label'>Claim Text 3</label>
                        <Input
                          type='text'
                          name='claimText3'
                          placeholder='claim text 3'
                          value={data?.claimText3}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg='6'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Supporting Product Details</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <label className='form-control-label'>Usage Tips</label>
                      <Editor
                        editorState={tipsState}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        readOnly={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Product Information
                      </label>
                      <Editor
                        editorState={infoState}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        readOnly={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Ingredients
                      </label>
                      <Editor
                        editorState={ingredientState}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        readOnly={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>FAQ</label>
                      <Editor
                        editorState={faqState}
                        wrapperClassName='wrapper-class'
                        editorClassName='editor-class'
                        toolbarClassName='toolbar-class'
                        readOnly={true}
                      />
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className='mb-5'>
              <div className='text-right'>
              <Button
                type='button'
                color='primary'
                onClick={() => {
                  history.push(`/admin/list-make-up`);
                }}
              >
                Back
              </Button>
              <Button
                type='button'
                color='dark'
                onClick={() => {
                  history.push(`/admin/edit-make-up/${id}`);
                }}
                outline
              >
                Edit
              </Button>
              <Button
                type='button'
                color='danger'
                onClick={handleDelete}
                outline
              >
                Delete
              </Button>
            </div>
            </Col>
          </Row>
        </Form>
      )}
      </Container>
    </>
  );
};

export default DetailProduct;
