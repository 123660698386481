import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const DetailHomeBanner = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const { data, status } = qoreContext.view('allHomeBanner').useGetRow(id);
  const { deleteRow } = qoreContext.view('allHomeBanner').useDeleteRow();

  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you sure to DELETE this banner?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRow(id)
          .then(() => {
            Swal.fire({
              icon: 'success',
              text: 'Home banner has been deleted.',
            });
            history.goBack();
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: 'Fail to update request status (Server Error)',
            });
            console.error(error);
          });
      }
    });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Home Banner'
        name='Detail Home Banner'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Detail Home Banner</h3>
              </CardHeader>
              <CardBody>
                {status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form>
                    <FormGroup>
                      <label className='form-control-label'>Index</label>
                      <Input
                        type='number'
                        name='index'
                        placeholder='Index'
                        value={data.index}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Banner Name</label>
                      <Input
                        type='text'
                        name='description'
                        placeholder='Description of Home Banner'
                        value={data.name}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Input
                        type='text'
                        name='description'
                        placeholder='Description of Home Banner'
                        value={data.description}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Link</label>
                      <Input
                        type='url'
                        name='link'
                        placeholder='https://www.google.co.id'
                        value={data.link}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Desktop Image
                      </label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={data.img}
                          alt='desktop_image'
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Mobile Image</label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={data.imgMobile}
                          alt='mobile_image'
                        />
                      </div>
                    </FormGroup>
                    <div className='text-right'>
                      <Button
                        type='button'
                        color='primary'
                        onClick={() => {
                          history.push(`/admin/list-home-banner`);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        type='button'
                        color='dark'
                        onClick={() => {
                          history.push(`/admin/edit-home-banner/${id}`);
                        }}
                        outline
                      >
                        Edit
                      </Button>
                      <Button
                        type='button'
                        color='danger'
                        onClick={handleDelete}
                        outline
                      >
                        Delete
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailHomeBanner;
