import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import Swal from 'sweetalert2';

const EditHomeBanner = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const client = qoreContext.useClient();
  const { data, status, revalidate } = qoreContext
    .view('allHomeBanner')
    .useGetRow(id);
  const { updateRow } = qoreContext.view('allHomeBanner').useUpdateRow();

  // state
  const [formBanner, setFormBanner] = useState(null);
  const [changeDesktop, setChangeDesktop] = useState(false);
  const [changeMobile, setChangeMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [uploadDone, setUploadDone] = useState(true);

  useEffect(() => {
    if (data && status === 'success') {
      setFormBanner(data);
    }
  }, [data, status]);

  const handleChange = (e) => {
    setFormBanner({ ...formBanner, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleImageDesktop = async (picture) => {
    const imageDesktop = picture[0];
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allHomeBanner')
      .upload(imageDesktop)
      .then((responseDesktop) => {
        setFormBanner({ ...formBanner, img: responseDesktop });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  const handleImageMobile = async (picture) => {
    const imageMobile = picture[0];
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allHomeBanner')
      .upload(imageMobile)
      .then((responseMobile) => {
        setFormBanner({ ...formBanner, imgMobile: responseMobile });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateRow(id, {
      description: formBanner?.description,
      link: formBanner?.link,
      index: formBanner?.index,
      img: formBanner?.img,
      imgMobile: formBanner?.imgMobile,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Home banner has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to update home banner',
        });
      });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Home Banner'
        name='Edit Home Banner'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Edit Home Banner</h3>
              </CardHeader>
              <CardBody>
                {!formBanner || loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Index</label>
                      <Input
                        type='number'
                        name='index'
                        placeholder='Index of Home Banner'
                        value={formBanner.index}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Input
                        type='text'
                        name='description'
                        placeholder='Description of Home Banner'
                        value={formBanner.description}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Link</label>
                      <Input
                        type='url'
                        name='link'
                        placeholder='https://www.google.co.id'
                        value={formBanner.link}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Desktop Image
                      </label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={formBanner.img}
                          alt='desktop_image'
                        />
                      </div>
                      {changeDesktop ? (
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={handleImageDesktop}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      ) : (
                        <Button
                          color='primary'
                          style={{ marginTop: '8px' }}
                          onClick={() => {
                            setChangeDesktop(true);
                          }}
                        >
                          Change Desktop Image
                        </Button>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Mobile Image</label>
                      <div>
                        <img
                          style={{ maxHeight: '360px' }}
                          src={formBanner.imgMobile}
                          alt='mobile_image'
                        />
                      </div>
                      {changeMobile ? (
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={handleImageMobile}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      ) : (
                        <Button
                          color='primary'
                          style={{ marginTop: '8px' }}
                          onClick={() => {
                            setChangeMobile(true);
                          }}
                        >
                          Change Mobile Image
                        </Button>
                      )}
                    </FormGroup>
                    <div className='text-right'>
                      <Button
                        type='submit'
                        color='primary'
                        disabled={!isEdited || !uploadDone ? true : false}
                      >
                        Submit
                      </Button>
                      <Button
                        type='button'
                        color='secondary'
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditHomeBanner;
