import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import randomString from 'randomstring';

const formBanner = {
  name: `HB-${randomString.generate(3)}`,
  description: '',
  link: '',
  index: 0,
  active: false,
};

const InputHomeBanner = () => {
  // state
  const [formData, setFormData] = useState(formBanner);
  const [imgDesktop, setImgDesktop] = useState('');
  const [imgMobile, setImgMobile] = useState('');
  const [loading, setLoading] = useState(false);

  // qore
  const client = qoreContext.useClient();
  const { insertRow } = qoreContext.view('allHomeBanner').useInsertRow();
  const { updateRow } = qoreContext.view('allHomeBanner').useUpdateRow();

  const { data: allBanners, status } = qoreContext
    .view('allHomeBanner')
    .useListRow({});

  // history
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageDesktop = (picture) => {
    setImgDesktop(picture[0]);
  };

  const handleImageMobile = (picture) => {
    setImgMobile(picture[0]);
  };

  useEffect(() => {
    if (allBanners.length > 0) {
      setFormData((formData) => ({
        ...formData,
        index: allBanners.length + 1,
      }));
    }
  }, [allBanners]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let idHomeBanner = '';
    let urlDesktop = '';
    let urlMobile = '';

    // 1. input data-data selain gambar menggunakan insertRow
    // 2. upload gambar desktop akan menghasilkan url
    // 3. upload gambar mobile akan menghasilkan url
    // 4. update row hasil langkah pertama dengan url-rul hasil langkah kedua dan ketiga

    insertRow(formData)
      .then((responseInsertBanner) => {
        idHomeBanner = responseInsertBanner.id;
        return client.view('allHomeBanner').upload(imgDesktop);
      })
      .then((responseDesktop) => {
        urlDesktop = responseDesktop;
        return client.view('allHomeBanner').upload(imgMobile);
      })
      .then((responseMobile) => {
        urlMobile = responseMobile;
        return updateRow(idHomeBanner, {
          img: urlDesktop,
          imgMobile: urlMobile,
        });
      })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Home banner has been submited',
        });
        setFormData(formBanner);
        setLoading(false);
        history.push('/admin/list-home-banner');
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to submit home banner',
        });
      });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Home Banner'
        name='Input Home Banner'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Home Banner</h3>
              </CardHeader>
              <CardBody>
                {loading || status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Index</label>
                      <Input
                        type='number'
                        name='index'
                        placeholder='Index of Home Banner'
                        value={formData.index}
                        onChange={handleChange}
                        disabled={true}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Input
                        type='text'
                        name='description'
                        placeholder='Description of Home Banner'
                        value={formData.description}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Link</label>
                      <Input
                        type='url'
                        name='link'
                        placeholder='https://www.google.co.id'
                        value={formData.link}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Desktop Image
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 1160 x 580 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleImageDesktop}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Mobile Image</label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 720 x 960 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleImageMobile}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputHomeBanner;
