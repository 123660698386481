import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/Admin.js';
// import RTLLayout from 'layouts/RTL.js';
import AuthLayout from 'layouts/Auth.js';
// import IndexView from 'views/Index.js';
import Cookies from 'js-cookie';

const App = () => {
  const token = Cookies.get('token');

  if (!token) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/auth' render={(props) => <AuthLayout {...props} />} />
          <Route path='/' render={(props) => <AuthLayout {...props} />} />
          <Redirect from='*' to='/' />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
        <Route path='/' render={(props) => <AdminLayout {...props} />} />
        <Redirect from='*' to='/' />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
