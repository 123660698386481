import React, { useState } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const formContentSubmission = {
  name: '',
  email: '',
  rating: 0,
  title: '',
  review: '',
  source: '',
  date: '',
  show: false,
};

const ProductReviewInput = () => {
  // state
  const [formData, setFormData] = useState(formContentSubmission);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState('');

  // qore
  const { insertRow } = qoreContext.view('allProductReview').useInsertRow();
  const { data: allProducts } = qoreContext.view('allProducts').useListRow({});

  // history
  const history = useHistory();

  const handleChange = (e) => {
    if (e.target.name === 'rating') {
      setFormData({ ...formData, [e.target.name]: parseInt(e.target.value) });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleProductChange = (e) => {
    setProductData(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { id: reviewId } = await insertRow(formData);
      if (reviewId) {
        const relationAdded = await qoreContext.client
          .view('allProductReview')
          .addRelation(reviewId, { products: [productData] });

        if (relationAdded) {
          Swal.fire({
            icon: 'success',
            text: 'Product Review has been submitted',
          });

          setFormData({
            name: '',
            email: '',
            rating: 0,
            title: '',
            review: '',
            source: '',
            date: '',
            show: false,
          });

          setLoading(false);

          history.push('/admin/product-review-list');
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Cannot submit Product Review',
      });
    }
  };

  return (
    <>
      <AlternativeHeader
        parentName='Input Product Review'
        name='Input Product Review'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='12'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Product Review</h3>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg='6'>
                        <FormGroup>
                          <label className='form-control-label'>Name</label>
                          <Input
                            type='text'
                            name='name'
                            placeholder="Reviewer's Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className='form-control-label'>Email</label>
                          <Input
                            type='text'
                            name='email'
                            placeholder="Reviewer's Email Address"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className='form-control-label'
                            htmlFor='exampleFormControlSelect3'
                          >
                            Review Rating
                          </label>
                          <Input
                            id='exampleFormControlSelect3'
                            type='select'
                            name='rating'
                            onChange={handleChange}
                          >
                            <option>(Select Rating)</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <label
                            className='form-control-label'
                            htmlFor='exampleFormControlSelect3'
                          >
                            Reviewed Product
                          </label>
                          <Input
                            id='exampleFormControlSelect3'
                            type='select'
                            name='products'
                            onChange={handleProductChange}
                          >
                            <option>(Select Product)</option>
                            {allProducts
                              ? allProducts.map((productData, index) => {
                                  return (
                                    <option key={index} value={productData.id}>
                                      {productData.no} {productData.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <label className='form-control-label'>
                            Review Date
                          </label>
                          <Input
                            type='date'
                            name='date'
                            value={formData.date}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg='6'>
                        <FormGroup>
                          <label className='form-control-label'>Title</label>
                          <Input
                            type='text'
                            name='title'
                            placeholder='Review Title'
                            value={formData.title}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className='form-control-label'>
                            Review Text
                          </label>
                          <Input
                            type='textarea'
                            name='review'
                            rows='6'
                            placeholder='Review Text'
                            value={formData.review}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className='form-control-label'
                            htmlFor='exampleFormControlSelect3'
                          >
                            Review Source
                          </label>
                          <Input
                            id='exampleFormControlSelect3'
                            type='select'
                            name='source'
                            onChange={handleChange}
                          >
                            <option>(Select Review Source)</option>
                            <option value='OMG Website'>OMG Website</option>
                            <option value='OMG Website'>Shopee</option>
                            <option value='OMG Website'>Tokopedia</option>
                            <option value='OMG Website'>Lazada</option>
                            <option value='OMG Website'>Blibli</option>
                            <option value='OMG Website'>Bukalapak</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductReviewInput;
