import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import randomString from 'randomstring';

const formTutorial = {
  name: `Tutorial-${randomString.generate(3)}`,
  link: '',
  active: false,
  index: 0,
};

const InputBeautyTutorial = () => {
  // state
  const [formData, setFormData] = useState(formTutorial);
  const [loading, setLoading] = useState(false);

  // qore
  const { insertRow } = qoreContext.view('allBeautyTutorial').useInsertRow();

  const { data: allTutorials, status } = qoreContext
    .view('allBeautyTutorial')
    .useListRow({});

  // history
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChangeActive = (e) => {
    let status = false;
    if (e.target.value === 'true') {
      status = true;
    } else {
      status = false;
    }
    setFormData({ ...formData, active: status });
  };

  useEffect(() => {
    if (allTutorials.length > 0) {
      setFormData((formData) => ({
        ...formData,
        index: allTutorials.length + 1,
      }));
    }
  }, [allTutorials]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    insertRow(formData)
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Beauty Tutotial has been submited',
        });
        setFormData(formTutorial);
        setLoading(false);
        history.push('/admin/beauty-tutorial-list');
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to submit home banner',
        });
      });
  };
  return (
    <>
      <AlternativeHeader
        parentName='Input Beauty Tutorial'
        name='Input Beauty Tutorial'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col className='order-xl-1' xl='12'>
            <Card>
              <CardHeader>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h3 className='mb-0'>Input Beauty Tutorial</h3>
                  </Col>
                  <Col className='text-right' xs='4'>
                    <Button
                      color='primary'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                      size='sm'
                    >
                      Change Name
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {loading || status !== 'success' ? (
                <div className='text-center'>
                  <Spinner />
                  <p>loading</p>
                </div>
              ) : (
                <CardBody>
                  <Form>
                    <h6 className='heading-small text-muted mb-4'>
                      Add Beauty Tutorial
                    </h6>
                    <div className='pl-lg-4'>
                      <Row>
                        <Col lg='6'>
                          <FormGroup>
                            <label className='form-control-label'>Index</label>
                            <Input
                              type='number'
                              name='index'
                              placeholder='Index of Beauty Tutorial'
                              value={formData.index}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className='form-control-label'
                              htmlFor='input-country'
                            >
                              Beauty Tutorial Name
                            </label>
                            <Input
                              defaultValue=''
                              id='input-country'
                              placeholder='Name here'
                              type='text'
                              name='name'
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <h6 className='heading-small text-muted mb-4'>
                        Beauty Tutorial Details
                      </h6>
                      <div className='pl-lg-4'>
                        <Row>
                          <Col lg='6'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='input-country'
                              >
                                Youtube Link
                              </label>
                              <Input
                                type='url'
                                name='link'
                                defaultValue=''
                                id='input-country'
                                placeholder='Link here'
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg='3'>
                            <FormGroup>
                              <label
                                className='form-control-label'
                                htmlFor='exampleFormControlSelect3'
                              >
                                Beauty Tutorials Active
                              </label>
                              <Input
                                id='exampleFormControlSelect3'
                                type='select'
                                name='active'
                                onChange={handleChangeActive}
                              >
                                <option value='false'>NON ACTIVE</option>
                                <option value='true'>ACTIVE</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col lg='12'>
                            <Button
                              color='primary'
                              href='#pablo'
                              onClick={handleSubmit}
                              size='lg'
                              style={{ marginBottom: '20px' }}
                            >
                              Submit Materi
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputBeautyTutorial;
