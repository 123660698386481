import React, { useState, useEffect } from 'react';

// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Spinner,
  CardBody,
  Button,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';

// pagination
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Show{' '}
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{' '}
        entries.
      </label>
    </div>
  ),
});

// search bar
const { SearchBar } = Search;

const PollResponseList = () => {
  const [pollFields, setPollFields] = useState([]);
  const [exportedPollFields, setExportedPollFields] = useState([]);
  const [exportedResponsesData, setExportedResponsesData] = useState([]);

  const {
    data: pollResponses,
    status: pollResponsesStatus,
  } = qoreContext.view("allSkincarePollResponses").useListRow();

  const {
    data: skincarePolls,
    status: skincarePollsStatus,
  } = qoreContext.view("allSkincarePoll").useListRow({ '$by.questionIndex': 'asc' });

  useEffect(() => {
    async function fetchData() {
      let pollFields = [];
      let tempExportedFields = [];
      let tempExportedResponses = pollResponses;
      let tempSkincarePolls = skincarePolls;
      for (let i = 0; i < tempSkincarePolls.length; i++){
        pollFields.push({ dataField: tempSkincarePolls[i].questionSlug, text: tempSkincarePolls[i].questionTitle, sort: true });
        tempExportedFields.push({ key: tempSkincarePolls[i].questionSlug, label: tempSkincarePolls[i].questionTitle });
      }
      tempExportedResponses.forEach(function(value){ delete value.createdAt; delete value.id; delete value.updatedAt; });

      setExportedPollFields(tempExportedFields);
      setExportedResponsesData(tempExportedResponses);
      setPollFields(pollFields);
    }

    fetchData();
    
  }, [skincarePolls, pollResponses])

  return (
    <>
      <AlternativeHeader parentName='Manage Survey & Poll' name='Responses List' />
      <Container className='mt--6' fluid>
        <Row>
          <div className='col'>
            <div className='card-wrapper'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Responses List</h3>
                </CardHeader>
                {pollResponsesStatus === 'success'
                && skincarePollsStatus === 'success'
                && pollResponses.length > 0
                && skincarePolls.length > 0
                && pollFields.length > 0 ? (
                  <ToolkitProvider
                    data={pollResponses}
                    keyField="pollResponses"
                    columns={pollFields}
                    search
                  >
                    {(props) => (
                      <div className='py-4 table-responsive'>
                        <Row>
                          <Col lg="9">
                            <div
                              id='datatable-basic_filter'
                              className='dataTables_filter px-4'
                            >
                            <label>
                              Search:
                              <SearchBar
                                className='form-control-sm'
                                placeholder=''
                                {...props.searchProps}
                              />
                            </label>
                            </div>
                          </Col>
                          <Col lg="3" align="right">
                            <Button
                              color="white"
                              type="submit"
                              className="mb-4 p-2 mr-4"
                              size="sm"
                            >
                              <CSVLink className="button mb-3" data={exportedResponsesData} headers={exportedPollFields}>
                                Export Responses Data
                              </CSVLink>
                            </Button>
                          </Col>
                        </Row>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField='id'
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <CardBody>
                    <div className='text-center' style={{ minHeight: '50vh' }}>
                      <Spinner />
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PollResponseList;