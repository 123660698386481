import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const DetailQuestion = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const { data, status } = qoreContext.view('allQuiz').useGetRow(id);
  const { deleteRow } = qoreContext.view('allQuiz').useDeleteRow();

  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you sure to DELETE this question?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRow(id)
          .then(() => {
            Swal.fire({
              icon: 'success',
              text: 'question has been deleted.',
            });
            history.goBack();
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: 'Fail to delete question (Server Error)',
            });
            console.error(error);
          });
      }
    });
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Quiz'
        name='Detail Question'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Detail Question</h3>
              </CardHeader>
              <CardBody>
                {status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form>
                    <FormGroup>
                      <label className='form-control-label'>Question</label>
                      <Input
                        type='text'
                        name='question'
                        placeholder='Insert question'
                        value={data.question}
                        readOnly
                      />
                    </FormGroup>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 1</label>
                          <Input
                            type='text'
                            name='choice1'
                            placeholder='Insert 1st choice'
                            value={data.choice1}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 1</label>
                          <Editor value={data.answer1} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 1</label>
                          <div>
                            <img
                              style={{ maxHeight: '240px' }}
                              src={data.imgOne}
                              alt='choice one'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 2</label>
                          <Input
                            type='text'
                            name='choice2'
                            placeholder='Insert 2nd choice'
                            value={data.choice2}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 2</label>
                          <Editor value={data.answer2} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 2</label>
                          <div>
                            <img
                              style={{ maxHeight: '240px' }}
                              src={data.imgTwo}
                              alt='choice two'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 3</label>
                          <Input
                            type='text'
                            name='choice3'
                            placeholder='Insert 3rd choice'
                            value={data.choice3}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 3</label>
                          <Editor value={data.answer3} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 3</label>
                          <div>
                            <img
                              style={{ maxHeight: '240px' }}
                              src={data.imgThree}
                              alt='choice three'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 4</label>
                          <Input
                            type='text'
                            name='choice4'
                            placeholder='Insert 4th choice'
                            value={data.choice4}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 4</label>
                          <Editor value={data.answer4} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 4</label>
                          <div>
                            <img
                              style={{ maxHeight: '240px' }}
                              src={data.imgFour}
                              alt='choice four'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 5</label>
                          <Input
                            type='text'
                            name='choice5'
                            placeholder='Insert 5th choice'
                            value={data.choice5}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 5</label>
                          <Editor value={data.answer5} readOnly />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 5</label>
                          <div>
                            <img
                              style={{ maxHeight: '360px' }}
                              src={data.imgFive}
                              alt='choice five'
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className='text-right'>
                      <Button
                        type='button'
                        color='primary'
                        onClick={() => {
                          history.push(`/admin/list-question`);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        type='button'
                        color='dark'
                        onClick={() => {
                          history.push(`/admin/edit-question/${id}`);
                        }}
                        outline
                      >
                        Edit
                      </Button>
                      <Button
                        type='button'
                        color='danger'
                        onClick={handleDelete}
                        outline
                      >
                        Delete
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailQuestion;
