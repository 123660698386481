import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import randomString from 'randomstring';

const formContentSubmission = {
  name: `KOL-${randomString.generate(3)}`,
  description: '',
  img: '',
  sign: '',
  active: false,
  index: 0,
};

const InputKOLReview = () => {
  // state
  const [formData, setFormData] = useState(formContentSubmission);
  const [imgDesktop, setImgDesktop] = useState('');
  const [imgWebp, setImgWebp] = useState(null);
  const [loading, setLoading] = useState(false);

  // qore
  const client = qoreContext.useClient();
  const { insertRow } = qoreContext.view('allKolReview').useInsertRow();
  const { updateRow } = qoreContext.view('allKolReview').useUpdateRow();

  const { data: allReviews, status } = qoreContext
    .view('allKolReview')
    .useListRow({});

  // history
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeImage = (picture) => {
    setImgDesktop(picture[0]);
  };

  const handleWebp = (picture) => {
    setImgWebp(picture[0]);
  };

  const handleChangeActive = (e) => {
    let status = false;
    if (e.target.value === 'true') {
      status = true;
    } else {
      status = false;
    }
    setFormData({ ...formData, active: status });
  };

  useEffect(() => {
    if (allReviews.length > 0) {
      setFormData((formData) => ({
        ...formData,
        index: allReviews.length + 1,
      }));
    }
  }, [allReviews]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // let idContentSubmission = '';
    // let urlDesktop = '';

    // insertRow(formData)
    //   .then((responseInsertCS) => {
    //     idContentSubmission = responseInsertCS.id;
    //     return client.view('allKolReview').upload(imgDesktop);
    //   })
    //   .then((responseDesktop) => {
    //     urlDesktop = responseDesktop;
    //     return updateRow(idContentSubmission, {
    //       img: urlDesktop,
    //     });
    //   })
    //   .then(() => {
    //     Swal.fire({
    //       icon: 'success',
    //       text: 'KOL Review has been submited',
    //     });
    //     setFormData(formContentSubmission);
    //     setLoading(false);
    //     history.push('/admin/kol-review-list');
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     Swal.fire({
    //       icon: 'error',
    //       text: 'Fail to submit KOL Review',
    //     });
    //   });

    try {
      const articleResponse = await insertRow(formData);
      const imageUrl = await client.view('allKolReview').upload(imgDesktop);
      const idArticle = articleResponse.id;

      if (!imgWebp) {
        await updateRow(idArticle, { img: imageUrl });
        Swal.fire({
          icon: 'success',
          text: 'KOL Review has been submited',
        });
        setFormData(formContentSubmission);
        setLoading(false);
        history.push('/admin/kol-review-list');
      } else {
        const webpUrl = await client.view('allBeautyTips').upload(imgWebp);
        await updateRow(idArticle, { img: imageUrl, imgWebp: webpUrl });
        Swal.fire({
          icon: 'success',
          text: 'KOL Review has been submited',
        });
        setFormData(formContentSubmission);
        setLoading(false);
        history.push('/admin/kol-review-list');
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        text: 'Fail to submit KOL Review',
      });
    }
  };
  return (
    <>
      <AlternativeHeader
        parentName='Input KOL Review'
        name='Input KOL Review'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input KOL Review</h3>
              </CardHeader>
              <CardBody>
                {loading || status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Index</label>
                      <Input
                        type='number'
                        name='index'
                        placeholder='Index of Home Banner'
                        value={formData.index}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Name</label>
                      <Input
                        type='text'
                        name='name'
                        placeholder='Description of KOL Review'
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Description</label>
                      <Input
                        type='text'
                        name='description'
                        placeholder='Description of KOL Review'
                        value={formData.description}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Image Content
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 752 x 564 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleChangeImage}
                        label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Image Content (.webp Format)
                      </label>
                      <h5 style={{ textAlign: 'center', color: 'grey' }}>
                        Size recommendation : 752 x 564 px
                      </h5>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        singleImage={true}
                        buttonText='Choose Image'
                        onChange={handleWebp}
                        label='Max : 2 MB, accepted: webp'
                        imgExtension={['.webp']}
                        maxFileSize={2097152}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>Sign</label>
                      <Input
                        type='text'
                        name='sign'
                        placeholder='Description of KOL Review'
                        value={formData.sign}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='exampleFormControlSelect3'
                      >
                        KOL Review Active
                      </label>
                      <Input
                        id='exampleFormControlSelect3'
                        type='select'
                        name='active'
                        onChange={handleChangeActive}
                      >
                        <option value='false'>NON ACTIVE</option>
                        <option value='true'>ACTIVE</option>
                      </Input>
                    </FormGroup>

                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputKOLReview;
