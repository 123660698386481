import React, { useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const formQuestion = {
  question: '',
  choice1: '',
  choice2: '',
  choice3: '',
  choice4: '',
  choice5: '',
  answer1: {
    answer: [
      {
        noLipstick: '01',
        scoreLipstick: 10,
      },
      {
        noLipstick: '04',
        scoreLipstick: 9,
      },
      {
        noLipstick: '10',
        scoreLipstick: 8,
      },
    ],
  },
  answer2: {
    answer: [
      {
        noLipstick: '01',
        scoreLipstick: 10,
      },
      {
        noLipstick: '04',
        scoreLipstick: 9,
      },
      {
        noLipstick: '10',
        scoreLipstick: 8,
      },
    ],
  },
  answer3: {
    answer: [
      {
        noLipstick: '01',
        scoreLipstick: 10,
      },
      {
        noLipstick: '04',
        scoreLipstick: 9,
      },
      {
        noLipstick: '10',
        scoreLipstick: 8,
      },
    ],
  },
  answer4: {
    answer: [
      {
        noLipstick: '01',
        scoreLipstick: 10,
      },
      {
        noLipstick: '04',
        scoreLipstick: 9,
      },
      {
        noLipstick: '10',
        scoreLipstick: 8,
      },
    ],
  },
  answer5: {
    answer: [
      {
        noLipstick: '01',
        scoreLipstick: 10,
      },
      {
        noLipstick: '04',
        scoreLipstick: 9,
      },
      {
        noLipstick: '10',
        scoreLipstick: 8,
      },
    ],
  },
  imgOne: '',
  imgTwo: '',
  imgThree: '',
  imgFour: '',
  imgFive: '',
};

const InputQuestion = () => {
  // state
  const [formData, setFormData] = useState(formQuestion);
  const [imgArray, setImgArray] = useState([]);
  const [loading, setLoading] = useState(false);

  // qore
  const client = qoreContext.useClient();
  const { insertRow } = qoreContext.view('allQuiz').useInsertRow();

  // history
  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDeleteImage = (deletedName) => {
    let newImages = [...imgArray];
    newImages = newImages.filter((item) => item.name !== deletedName);
    setImgArray(newImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // bikin promises, upload gambar
      const promises = imgArray.map((img) =>
        client
          .view('allQuiz')
          .upload(img.file)
          .then((url) => {
            return {
              name: img.name,
              url: url,
            };
          })
      );

      const imgUrls = await Promise.all(promises);

      // create new question masukkan juga hasil promises
      const one = imgUrls.find((img) => img.name === 'imgOne');
      const two = imgUrls.find((img) => img.name === 'imgTwo');
      const three = imgUrls.find((img) => img.name === 'imgThree');
      const four = imgUrls.find((img) => img.name === 'imgFour');
      const five = imgUrls.find((img) => img.name === 'imgFive');

      const newQuestion = await insertRow({
        question: formData.question,
        choice1: formData.choice1,
        choice2: formData.choice2,
        choice3: formData.choice3,
        choice4: formData.choice4,
        choice5: formData.choice5,
        answer1: formData.answer1,
        answer2: formData.answer2,
        answer3: formData.answer3,
        answer4: formData.answer4,
        answer5: formData.answer5,
        imgOne: one.url,
        imgTwo: two.url,
        imgThree: three.url,
        imgFour: four.url,
        imgFive: five.url,
      });

      if (newQuestion) {
        Swal.fire({
          icon: 'success',
          text: 'Question has been submited',
        });
        setFormData(formQuestion);
        setLoading(false);
        history.push('/admin/list-question');
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Fail to submit question',
      });
    }
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Beauty Quiz'
        name='Input Question'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Question</h3>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <label className='form-control-label'>Question</label>
                      <Input
                        type='text'
                        name='question'
                        placeholder='Insert question'
                        value={formData.no}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 1</label>
                          <Input
                            type='text'
                            name='choice1'
                            placeholder='Insert 1st choice'
                            value={formData.choice1}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 1</label>
                          <Editor
                            value={formData.answer1}
                            onChange={(e) => {
                              setFormData({ ...formData, answer1: e });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 1</label>
                          <h5 style={{ textAlign: 'center', color: 'grey' }}>
                            Size recommendation : 216 x 216 px
                          </h5>
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              e.length > 0
                                ? setImgArray((oldArr) => [
                                    ...oldArr,
                                    {
                                      name: 'imgOne',
                                      file: e[0],
                                    },
                                  ])
                                : handleDeleteImage('imgOne');
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 2</label>
                          <Input
                            type='text'
                            name='choice2'
                            placeholder='Insert 2nd choice'
                            value={formData.choice2}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 2</label>
                          <Editor
                            value={formData.answer2}
                            onChange={(e) => {
                              setFormData({ ...formData, answer2: e });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 2</label>
                          <h5 style={{ textAlign: 'center', color: 'grey' }}>
                            Size recommendation : 216 x 216 px
                          </h5>
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              e.length > 0
                                ? setImgArray((oldArr) => [
                                    ...oldArr,
                                    {
                                      name: 'imgTwo',
                                      file: e[0],
                                    },
                                  ])
                                : handleDeleteImage('imgTwo');
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 3</label>
                          <Input
                            type='text'
                            name='choice3'
                            placeholder='Insert 3rd choice'
                            value={formData.choice3}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 3</label>
                          <Editor
                            value={formData.answer3}
                            onChange={(e) => {
                              setFormData({ ...formData, answer3: e });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 3</label>
                          <h5 style={{ textAlign: 'center', color: 'grey' }}>
                            Size recommendation : 216 x 216 px
                          </h5>
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              e.length > 0
                                ? setImgArray((oldArr) => [
                                    ...oldArr,
                                    {
                                      name: 'imgThree',
                                      file: e[0],
                                    },
                                  ])
                                : handleDeleteImage('imgThree');
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 4</label>
                          <Input
                            type='text'
                            name='choice4'
                            placeholder='Insert 4th choice'
                            value={formData.choice4}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 4</label>
                          <Editor
                            value={formData.answer4}
                            onChange={(e) => {
                              setFormData({ ...formData, answer4: e });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 4</label>
                          <h5 style={{ textAlign: 'center', color: 'grey' }}>
                            Size recommendation : 216 x 216 px
                          </h5>
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              e.length > 0
                                ? setImgArray((oldArr) => [
                                    ...oldArr,
                                    {
                                      name: 'imgFour',
                                      file: e[0],
                                    },
                                  ])
                                : handleDeleteImage('imgFour');
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Choice 5</label>
                          <Input
                            type='text'
                            name='choice5'
                            placeholder='Insert 5th choice'
                            value={formData.choice5}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Point 5</label>
                          <Editor
                            value={formData.answer5}
                            onChange={(e) => {
                              setFormData({ ...formData, answer5: e });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className='form-control-label'>Image 5</label>
                          <h5 style={{ textAlign: 'center', color: 'grey' }}>
                            Size recommendation : 216 x 216 px
                          </h5>
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Choose Image'
                            onChange={(e) => {
                              e.length > 0
                                ? setImgArray((oldArr) => [
                                    ...oldArr,
                                    {
                                      name: 'imgFive',
                                      file: e[0],
                                    },
                                  ])
                                : handleDeleteImage('imgFive');
                            }}
                            label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                            imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                            maxFileSize={2097152}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className='text-right'>
                      <Button type='submit' color='primary'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputQuestion;
