import React, { useState, useEffect } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import ImageUploader from 'react-images-upload';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const EditProduct = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  // qore
  const client = qoreContext.useClient();
  const { data, status, revalidate } = qoreContext
    .view('allProducts')
    .useGetRow(id);
  const { updateRow } = qoreContext.view('allProducts').useUpdateRow();

  // state
  const [formData, setFormData] = useState(null);
  const [imgsChange, setimgsChange] = useState({
    imgProduct: false,
    imgBottle: false,
    imgApplicator: false,
    imgSwatch: false,
    imgLip: false,
    imgHand: false,
    imgModel: false,
    claimImage1: false,
    claimImage2: false,
    claimImage3: false,
  });

  const [tipsState, setTipsState] = useState(() =>
    EditorState.createEmpty()
  );

  const [infoState, setInfoState] = useState(() =>
    EditorState.createEmpty()
  );

  const [ingredientState, setIngredientState] = useState(() =>
    EditorState.createEmpty()
  );

  const [faqState, setFaqState] = useState(() =>
    EditorState.createEmpty()
  );

  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [uploadDone, setUploadDone] = useState(true);

  useEffect(() => {
    if (data && status === 'success') {
      setFormData(data);
      convertHtmlToDraft(data.tipsPenggunaan, 'tipsPengunaan');
      convertHtmlToDraft(data.productInformation, 'productInformation');
      convertHtmlToDraft(data.ingredients, 'ingredients');
      convertHtmlToDraft(data.faq, 'faq');
    }
  }, [data, status]);

  const convertHtmlToDraft = (articleHTML, text) => {
    if (articleHTML) {
      let contentBlock = htmlToDraft(articleHTML);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const article = EditorState.createWithContent(contentState);
  
        if (text === 'tipsPengunaan') {
          setTipsState(article);
        } else if (text === 'productInformation') {
          setInfoState(article);
        } else if (text === 'ingredients') {
          setIngredientState(article);
        } else if (text === 'faq') {
          setFaqState(article);
        }
      }
    }
  };

  useEffect(() => {
    if (data && status === 'success') {
      setFormData(data);
    }
  }, [data, status]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleUploadImage = async (targetName, file) => {
    setIsEdited(true);
    setUploadDone(false);

    client
      .view('allProducts')
      .upload(file)
      .then((urlImage) => {
        setFormData({ ...formData, [targetName]: urlImage });
        setUploadDone(true);
      })
      .catch((err) => {
        console.error(err);
        setUploadDone(true);
      });
  };

  const handleTipsChage = (state) => {
    setTipsState(state);

    let currentContentAsHTML = draftToHtml(
      convertToRaw(state.getCurrentContent())
    );

    setFormData({ ...formData, 'tipsPenggunaan': currentContentAsHTML });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleInfoChange = (state) => {
    setInfoState(state);
    
    let currentContentAsHTML = draftToHtml(
      convertToRaw(state.getCurrentContent())
    );

    setFormData({ ...formData, 'productInformation': currentContentAsHTML });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleIgrdtsChange = (state) => {
    setIngredientState(state);
    
    let currentContentAsHTML = draftToHtml(
      convertToRaw(state.getCurrentContent())
    );

    setFormData({ ...formData, 'ingredients': currentContentAsHTML });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleFAQChange = (state) => {
    setFaqState(state);
    
    let currentContentAsHTML = draftToHtml(
      convertToRaw(state.getCurrentContent())
    );

    setFormData({ ...formData, 'faq': currentContentAsHTML });
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      client
        .view('allProducts')
        .upload(file)
        .then((url) => {
          resolve({ data: { link: url } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateRow(id, {
      no: formData.no,
      name: formData.name,
      parentName: formData.parentName,
      hexColor: formData.hexColor,
      bpom: formData.bpom,
      price: formData.price,
      productDescription: formData.productDescription,
      imgProduct: formData.imgProduct,
      imgBottle: formData.imgBottle,
      imgApplicator: formData.imgApplicator,
      imgSwatch: formData.imgSwatch,
      imgLip: formData.imgLip,
      imgHand: formData.imgHand,
      imgModel: formData.imgModel,
      claimImage1: formData.claimImage1,
      claimImage2: formData.claimImage2,
      claimImage3: formData.claimImage3,
      claimText1: formData.claimText1,
      claimText2: formData.claimText2,
      claimText3: formData.claimText3,
      tokopedia: formData.tokopedia,
      shopee: formData.shopee,
      blibli: formData.blibli,
      lazada: formData.lazada,
      tipsPenggunaan: formData.tipsPenggunaan,
      productInformation: formData.productInformation,
      ingredients: formData.ingredients,
      faq: formData.faq,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Product has been updated',
        });
        revalidate();
        setLoading(false);
        setIsEdited(false);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          text: 'Fail to update product',
        });
      });
  };

  return (
    <>
      <AlternativeHeader parentName='Manage Product (Make Up)' name='Edit Product (Make Up)' />
      <Container className='mt--6' fluid>
        {status !== 'success' || loading ? (
          <div className='text-center'>
            <Spinner />
            <p>loading</p>
          </div>
        ) : (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg='6'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Edit Main Data</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <label className='form-control-label'>No</label>
                        <Input
                          type='text'
                          name='no'
                          placeholder='Insert product number'
                          value={formData?.no}
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={9}>
                      <FormGroup>
                        <label className='form-control-label'>Product Shade</label>
                        <Input
                          type='text'
                          name='name'
                          placeholder='Insert Product Shade Name'
                          value={formData?.name}
                          onChange={handleChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label className='form-control-label'>
                      Product Name
                    </label>
                    <Input
                      type='text'
                      name='parentName'
                      placeholder='Insert Product Name'
                      value={formData?.parentName}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Hexcolor (ex: #cf578a)
                    </label>
                    <Input
                      type='text'
                      name='hexColor'
                      placeholder='Insert hexcolor code'
                      value={formData?.hexColor}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>BPOM</label>
                    <Input
                      type='text'
                      name='bpom'
                      placeholder='Insert BPOM number'
                      value={formData?.bpom}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Price</label>
                    <Input
                      type='number'
                      name='price'
                      placeholder='Insert product price'
                      value={formData?.price}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Product Description</label>
                    <Input
                      type='textarea'
                      rows='5'
                      name='productDescription'
                      placeholder='Insert product description'
                      value={formData?.productDescription}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className='form-control-label'>Shopee</label>
                        <Input
                          type='text'
                          name='shopee'
                          placeholder='insert product link on shopee'
                          value={formData?.shopee}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className='form-control-label'>
                          Tokopedia
                        </label>
                        <Input
                          type='text'
                          name='tokopedia'
                          placeholder='insert product link on tokopedia'
                          value={formData?.tokopedia}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className='form-control-label'>Lazada</label>
                        <Input
                          type='text'
                          name='lazada'
                          placeholder='insert product link on lazada'
                          value={formData?.lazada}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className='form-control-label'>Blibli</label>
                        <Input
                          type='text'
                          name='blibli'
                          placeholder='insert product link on blibli'
                          value={formData?.blibli}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label className='form-control-label'>
                      Product Image
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.imgProduct}
                        alt='product'
                      />
                    </div>
                    {imgsChange.imgProduct ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 400 x 400 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('imgProduct', e[0]);
                          }}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, imgProduct: true });
                        }}
                      >
                        Change Product Image
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Bottle Image</label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.imgBottle}
                        alt='bottle'
                      />
                    </div>
                    {imgsChange.imgBottle ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 400 x 400 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('imgBottle', e[0]);
                          }}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, imgBottle: true });
                        }}
                      >
                        Change Bottle Image
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Applicator Image
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.imgApplicator}
                        alt='applicator'
                      />
                    </div>
                    {imgsChange.imgApplicator ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 400 x 400 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('imgApplicator', e[0]);
                          }}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({
                            ...imgsChange,
                            imgApplicator: true,
                          });
                        }}
                      >
                        Change Applicator Image
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Popup Image</label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.imgSwatch}
                        alt='popup'
                      />
                    </div>
                    {imgsChange.imgSwatch ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 400 x 400 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('imgSwatch', e[0]);
                          }}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, imgSwatch: true });
                        }}
                      >
                        Change Popup Image
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Product on Lip
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.imgLip}
                        alt='lip'
                      />
                    </div>
                    {imgsChange.imgLip ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 400 x 400 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('imgLip', e[0]);
                          }}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, imgLip: true });
                        }}
                      >
                        Change Product on Lip
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Swatch on Hand
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.imgHand}
                        alt='hand'
                      />
                    </div>
                    {imgsChange.imgHand ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 400 x 400 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('imgHand', e[0]);
                          }}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, imgHand: true });
                        }}
                      >
                        Change Swatch on Hand
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Product on Model
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.imgModel}
                        alt='model'
                      />
                    </div>
                    {imgsChange.imgModel ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 400 x 400 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('imgModel', e[0]);
                          }}
                          label='Max : 2 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={2097152}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, imgModel: true });
                        }}
                      >
                        Change Product on Model
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Claim Image 1
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.claimImage1}
                        alt='Claim 1'
                      />
                    </div>
                    {imgsChange.claimImage1 ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 130 x 35 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('claimImage1', e[0]);
                          }}
                          label='Max : 1 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={1048576}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, claimImage1: true });
                        }}
                      >
                        Change Claim Image 1
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Claim Text 1</label>
                    <Input
                      type='text'
                      name='claimText1'
                      placeholder='Insert Claim Text 1'
                      value={formData?.claimText1}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Claim Image 2
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.claimImage2}
                        alt='Claim 2'
                      />
                    </div>
                    {imgsChange.claimImage2 ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 130 x 35 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('claimImage2', e[0]);
                          }}
                          label='Max : 1 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={1048576}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, claimImage2: true });
                        }}
                      >
                        Change Claim Image 2
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Claim Text 2</label>
                    <Input
                      type='text'
                      name='claimText2'
                      placeholder='Insert Claim Text 2'
                      value={formData?.claimText2}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      Claim Image 3
                    </label>
                    <div>
                      <img
                        style={{ maxHeight: '360px' }}
                        src={formData?.claimImage3}
                        alt='Claim 3'
                      />
                    </div>
                    {imgsChange.claimImage3 ? (
                      <>
                        <h5 style={{ textAlign: 'center', color: 'grey' }}>
                          Size recommendation : 130 x 35 px
                        </h5>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          buttonText='Choose Image'
                          onChange={(e) => {
                            handleUploadImage('claimImage3', e[0]);
                          }}
                          label='Max : 1 MB, accepted: jpg, jpeg, png, gif'
                          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                          maxFileSize={1048576}
                        />
                      </>
                    ) : (
                      <Button
                        color='primary'
                        style={{ marginTop: '8px' }}
                        onClick={() => {
                          setimgsChange({ ...imgsChange, claimImage3: true });
                        }}
                      >
                        Change Claim Image 3
                      </Button>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Claim Text 3</label>
                    <Input
                      type='text'
                      name='claimText3'
                      placeholder='Insert Claim Text 3'
                      value={formData?.claimText3}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col lg='6'>
              <Card>
                <CardHeader>
                  <h3 className='mb-0'>Supporting Data</h3>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <label className='form-control-label'>Usage Tips</label>
                    <Editor
                      editorState={tipsState}
                      editorStyle={{ fontFamily: 'Avenir' }}
                      onEditorStateChange={handleTipsChage}
                      wrapperClassName='wrapper-class'
                      editorClassName='editor-class'
                      toolbarClassName='toolbar-class'
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'textAlign',
                          'fontFamily',
                          'fontSize',
                          'link',
                          'image',
                          'history',
                        ],
                        fontFamily: { options: ['Avenir'] },
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                          uploadCallback: uploadImageCallBack,
                          alt: { present: true, mandatory: true },
                        },
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Product Information</label>
                    <Editor
                      editorState={infoState}
                      editorStyle={{ fontFamily: 'Avenir' }}
                      onEditorStateChange={handleInfoChange}
                      wrapperClassName='wrapper-class'
                      editorClassName='editor-class'
                      toolbarClassName='toolbar-class'
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'textAlign',
                          'fontFamily',
                          'fontSize',
                          'link',
                          'image',
                          'history',
                        ],
                        fontFamily: { options: ['Avenir'] },
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                          uploadCallback: uploadImageCallBack,
                          alt: { present: true, mandatory: true },
                        },
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>Ingredients</label>
                    <Editor
                      editorState={ingredientState}
                      editorStyle={{ fontFamily: 'Avenir' }}
                      onEditorStateChange={handleIgrdtsChange}
                      wrapperClassName='wrapper-class'
                      editorClassName='editor-class'
                      toolbarClassName='toolbar-class'
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'textAlign',
                          'fontFamily',
                          'fontSize',
                          'link',
                          'image',
                          'history',
                        ],
                        fontFamily: { options: ['Avenir'] },
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                          uploadCallback: uploadImageCallBack,
                          alt: { present: true, mandatory: true },
                        },
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label className='form-control-label'>
                      FAQ
                    </label>
                    <Editor
                      editorState={faqState}
                      editorStyle={{ fontFamily: 'Avenir' }}
                      onEditorStateChange={handleFAQChange}
                      wrapperClassName='wrapper-class'
                      editorClassName='editor-class'
                      toolbarClassName='toolbar-class'
                      toolbar={{
                        options: [
                          'inline',
                          'list',
                          'textAlign',
                          'fontFamily',
                          'fontSize',
                          'link',
                          'image',
                          'history',
                        ],
                        fontFamily: { options: ['Avenir'] },
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                        image: {
                          uploadCallback: uploadImageCallBack,
                          alt: { present: true, mandatory: true },
                        },
                      }}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className='mb-5'>
              <div className='text-right'>
                <Button
                  type='submit'
                  color='primary'
                  disabled={!isEdited || !uploadDone ? true : false}
                >
                  Submit
                </Button>
                <Button
                  type='button'
                  color='secondary'
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        )}
      </Container>
    </>
  );
};

export default EditProduct;
