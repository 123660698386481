import React, { useState } from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  CardBody,
  Spinner,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import { qoreContext } from '../../../context';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const DetailPollQuestion = () => {
  // params and history
  const { id } = useParams();
  const history = useHistory();

  const [endpoint] = useState(process.env.REACT_APP_QORE_ENDPOINT);
  const [orgId] = useState(process.env.REACT_APP_QORE_ORG_ID);
  const [projectId] = useState(process.env.REACT_APP_QORE_PROJECT_ID);

  // qore
  const { data, status } = qoreContext.view('allSkincarePoll').useGetRow(id);
  const { deleteRow } = qoreContext.view('allSkincarePoll').useDeleteRow();
  const { updateRow } = qoreContext.view('allSkincarePoll').useUpdateRow();
  const { data: skincarePolls } = qoreContext
    .view('allSkincarePoll')
    .useListRow({ '$by.questionIndex': 'asc' });

  const handleDelete = async () => {
    Swal.fire({
      title: 'Are you sure to DELETE this question?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteQuestion();
      }
    });
  };

  const updateOtherIndex = async () => {
    let tempResult = true;
    try {
      // @DESC UPDATING OTHER INDEXES
      let newSkincarePolls = skincarePolls.filter(
        (poll) => poll.id !== data.id
      );

      if (data.questionIndex !== data.length + 1) {
        // @DESC NOT THE LAST DATA
        for (const poll in newSkincarePolls) {
          await updateRow(poll.id, {
            questionIndex: poll.questionIndex - 1,
          });
        }
      }
    } catch (err) {
      console.error(err);
      tempResult = false;
    } finally {
      return tempResult;
    }
  };

  const deleteQuestion = async () => {
    try {
      const rowDeleted = await deleteRow(id);

      if (!rowDeleted) {
        const err = { msg: 'process delete row error' };
        throw err;
      }

      const indexUpdated = await updateOtherIndex();

      if (!indexUpdated) {
        const err = { msg: 'process index update fail' };
        throw err;
      }

      const {
        data: { ok: isQuestionDeleted },
      } = await qoreContext.client.project.axios.delete(
        `${endpoint}/orgs/${orgId}/projects/${projectId}/views/allSkincarePollResponses/fields/${data.questionSlug}`
      );

      if (!isQuestionDeleted) {
        const err = { msg: 'Question deleted with an error' };
        throw err;
      }

      Swal.fire({
        icon: 'success',
        text: 'The question has been deleted.',
      });
      history.push('/admin/list-poll-questions');
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: 'error',
        text: 'Fail to delete question (Server Error)',
      });
    }
  };

  return (
    <>
      <AlternativeHeader
        parentName='Manage Survey Form'
        name='Detail Question'
      />
      <Container className='mt--6' fluid>
        <Row>
          <Col lg='6'>
            <Card>
              <CardHeader>
                <h3 className='mb-0'>Input Question</h3>
              </CardHeader>
              <CardBody>
                {status !== 'success' ? (
                  <div className='text-center'>
                    <Spinner />
                    <p>loading</p>
                  </div>
                ) : (
                  <Form>
                    <Row>
                      <Col md={2}>
                        <FormGroup>
                          <label className='form-control-label'>Index</label>
                          <Input
                            type='number'
                            name='questionIndex'
                            value={data.questionIndex}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col md={10}>
                        <FormGroup>
                          <label className='form-control-label'>
                            Question Title
                          </label>
                          <Input
                            type='text'
                            name='questionTitle'
                            value={data.questionTitle}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label className='form-control-label'>
                        Question Sub Title
                      </label>
                      <Input
                        type='text'
                        name='question'
                        placeholder='Insert question'
                        value={data.subTitle}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Question Slug
                      </label>
                      <Input
                        type='text'
                        name='question'
                        placeholder='Insert question'
                        value={data.questionSlug}
                        readOnly
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className='form-control-label'>
                        Question Type
                      </label>
                      <Input
                        type='text'
                        name='question'
                        placeholder='Insert question'
                        value={
                          data.type === 'multipleChoice'
                            ? 'Multiple Choice'
                            : data.type === 'shortAnswer'
                            ? 'Short Answer'
                            : null
                        }
                        readOnly
                      />
                    </FormGroup>
                    {data.type === 'multipleChoice' ? (
                      data.choices.choice?.map((data, index) => (
                        <Row key={index}>
                          <Col>
                            <FormGroup>
                              <label className='form-control-label'>
                                Choice {index + 1}
                              </label>
                              <Input
                                type='text'
                                name='title'
                                value={data.title}
                                readOnly
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ))
                    ) : data.type === 'shortAnswer' ? (
                      <FormGroup>
                        <label className='form-control-label'>Image 2</label>
                        <div>
                          <img
                            style={{ maxHeight: '240px' }}
                            src={data.questionImg}
                            alt='choice two'
                          />
                        </div>
                      </FormGroup>
                    ) : null}
                    <div className='text-right'>
                      <Button
                        type='button'
                        color='primary'
                        onClick={() => {
                          history.push(`/admin/list-poll-questions`);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        type='button'
                        color='dark'
                        onClick={() => {
                          history.push(`/admin/edit-poll-question/${id}`);
                        }}
                        outline
                      >
                        Edit
                      </Button>
                      <Button
                        type='button'
                        color='danger'
                        onClick={handleDelete}
                        outline
                      >
                        Delete
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailPollQuestion;
